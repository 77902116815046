'use client';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const breakpointMobile = 767;
const breaktpointMinTablet = 768;
const breakpointTablet = 1023;

// fixes rehydration issue
// https://github.com/yocontra/react-responsive/issues/162#issuecomment-592082035
export function useResponsive() {
    // const isMobile = useMediaQuery({ query: '(max-width: 820px)' });
    // const isMobileDesign = useMediaQuery({ query: '(max-width: 1024px)' });
    // const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
    // const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

    // return { isMobile, isDesktop, isTablet, isMobileDesign };

    const [isClient, setIsClient] = useState(false);

    const isMobile = useMediaQuery({
        maxWidth: breakpointMobile,
    });

    const isTablet = useMediaQuery({
        minWidth: breaktpointMinTablet,
        maxWidth: breakpointTablet,
    });

    const isDesktop = useMediaQuery({
        minWidth: breakpointTablet,
    });

    const isMobileDesign = useMediaQuery({
        maxWidth: breakpointTablet,
    });

    useEffect(() => {
        if (typeof window !== 'undefined') setIsClient(true);
    }, []);

    return {
        isDesktop: isClient ? isDesktop : true,
        isTablet: isClient ? isTablet : false,
        isMobile: isClient ? isMobile : false,
        isMobileDesign: isClient ? isMobileDesign : false,
    };
}
export default useResponsive;
