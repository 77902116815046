'use client';
import { IconFacebook, IconInstagram, IconTwitter, IconYoutube, LogoMoar } from '@moar/svg';
import { useIsOnline } from '../context/online';

export interface FooterProps {
    padForBottomNav?: boolean;
}

export function Footer(props: FooterProps) {
    const { padForBottomNav = false } = props;
    const isOnline = useIsOnline();

    return (
        <div className="bg-midnight print:hidden">
            <div className="xl:container">
                <div className="mx-0 md:mx-10">
                    <div
                        className={`${
                            padForBottomNav ? 'pb-24' : 'pb-5'
                        } flex flex-col items-center justify-between pt-5 md:py-5 md:flex-row`}
                    >
                        <div className="mb-6 md:mb-0">
                            {isOnline && (
                                <a
                                    aria-label="Homepage for the Museum of the American Revolution"
                                    href="https://www.amrevmuseum.org/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <LogoMoar />
                                </a>
                            )}
                            {!isOnline && <LogoMoar />}
                        </div>
                        {isOnline && (
                            <div className="flex justify-between">
                                <a
                                    aria-label="Facebook"
                                    href="https://www.facebook.com/AmRevMuseum"
                                    target="_blank"
                                    className="mr-8 group"
                                    rel="noreferrer"
                                >
                                    <IconFacebook />
                                </a>
                                <a
                                    aria-label="Twitter"
                                    href="https://twitter.com/amrevmuseum"
                                    target="_blank"
                                    className="mr-8 group"
                                    rel="noreferrer"
                                >
                                    <IconTwitter />
                                </a>
                                <a
                                    aria-label="Instagram"
                                    href="https://www.instagram.com/amrevmuseum/"
                                    target="_blank"
                                    className="mr-8 group"
                                    rel="noreferrer"
                                >
                                    <IconInstagram />
                                </a>
                                <a
                                    aria-label="YouTube"
                                    href="https://www.youtube.com/c/MuseumoftheAmericanRevolution"
                                    target="_blank"
                                    className="group"
                                    rel="noreferrer"
                                >
                                    <IconYoutube />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
