'use client';
import { Banner, ParagraphSmall, useContextData } from '../../index';
import usePwaSupported from '../hooks/use-pwa-supported/use-pwa-supported';
import { useEffect, useState } from 'react';
import useAddToHomescreenPrompt from '../hooks/use-add-to-homescreen-prompt/use-add-to-homescreen-prompt';
import useUserAgent from '../hooks/use-user-agent/use-user-agent';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useInterval from '../hooks/use-interval/use-interval';

/* eslint-disable-next-line */
export interface BannerPwaPromptProps {}

// NOT IN USE = was flakly and only working sometimes, so we disabled it. And the content is not in the API anymore.
// query=standalone doesn't work on desktop
export function BannerPwaPrompt(props: BannerPwaPromptProps) {
    const data = useContextData();
    const [desktopPrompt, desktopPromptToInstall] = useAddToHomescreenPrompt();
    const [isVisibleDesktopPrompt, setIsVisibleDesktopPrompt] = useState(false);
    const userAgent = useUserAgent();
    const { isSupported, isSupportedDesktop, isSupportedSafari, isSupportedAndroid } = usePwaSupported();
    const { query } = useRouter();
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        console.log('%c DesktopPrompt CHANGED! ', 'color: orange', desktopPrompt);
        setIsVisibleDesktopPrompt(!!desktopPrompt);
    }, [desktopPrompt]);

    useInterval(
        () => {
            window.serwist.messageSW({
                command: 'log',
                message: 'ready ping',
                action: 'READY_PING',
            });
        },
        // Delay in milliseconds or null to stop it
        !isReady ? 2000 : null
    );

    useEffect(() => {
        const handleMessage = (event: any) => {
            if (isReady) return;
            // alert(JSON.stringify(event.data.payload));
            if (event.data.type === 'READY_PING_ANSWER') {
                console.log('%c PWA ready! ', 'background: #222; color: #bada55');
                setIsReady(true);
            }
        };

        window.serwist.addEventListener('message', handleMessage);

        return function cleanup() {
            window.serwist.removeEventListener('message', handleMessage);
        };
    }, [isReady]);

    const handleOnClick = async () => {
        const outcome = await desktopPromptToInstall();
        setIsVisibleDesktopPrompt(false);
    };

    console.log({
        userAgent,
        query,
        isVisibleDesktopPrompt,
        isSupportedDesktop,
        desktopPrompt,
        data,
    });

    // query.standalone does not work on desktop, so use prompt check for desktop
    if (!userAgent || query['standalone']) return null;
    if (!data) return null;
    const { alert } = data.data;

    if (!alert.desktopPromptInstructions) return null;

    // desktop - chrome & edge
    if (isSupportedDesktop) {
        // console.log({ desktopPrompt, desktopPromptToInstall, isVisibleDesktopPrompt });
        return (
            <div className="mode--browser">
                <Banner>
                    <div className="flex items-center">
                        <div className="flex flex-col items-start ml-0 space-x-0 md:items-center md:space-x-3 md:ml-2 md:flex-row">
                            <ParagraphSmall className="mb-3 mr-1 text-left text-black md:mb-0">
                                {alert.desktopPromptInstructions}
                            </ParagraphSmall>
                            <div className="flex justify-start space-x-4">
                                <Link
                                    href="/how-to/#desktop"
                                    aria-label="Learn more about the PWA"
                                    className="flex items-center justify-center font-bold text-black hover:text-blue group btn btn--outline-dark"
                                >
                                    Learn more
                                </Link>
                                {!isReady && <span className="font-serif text-xl animate-pulse">Loading...</span>}
                                {isReady && (
                                    <span className="font-serif text-base" style={{ width: '120px' }}>
                                        Ready to install in address bar
                                    </span>
                                )}
                                {/* {!isVisibleDesktopPrompt && (
                                    <span className="font-serif text-xl animate-pulse">Prompt...</span>
                                )} */}
                                {isVisibleDesktopPrompt && (
                                    <span className="block sm:ml-2 sm:inline-block">
                                        <button
                                            aria-label="Install PWA"
                                            onClick={() => handleOnClick()}
                                            className="btn btn--primary"
                                        >
                                            {alert.desktopPromptButtonLabel}
                                        </button>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </Banner>
            </div>
        );
    }
    // iphone and ipad safari
    if (isSupportedSafari) {
        return (
            <div className="mode--browser">
                <Banner>
                    <div className="flex items-center">
                        <div className="flex flex-col items-start ml-0 space-x-0 md:items-center md:space-x-3 md:ml-2 md:flex-row">
                            <ParagraphSmall className="mb-3 mr-1 text-left text-black md:mb-0">
                                {alert.safariPromptInstructions}
                            </ParagraphSmall>
                            <div className="flex justify-start">
                                <Link
                                    href="/how-to/#safari"
                                    aria-label="Learn more about the PWA"
                                    className="btn btn--outline-dark"
                                >
                                    Learn more
                                </Link>
                            </div>
                        </div>
                    </div>
                </Banner>
            </div>
        );
    }

    // chrome and ff on android
    if (isSupportedAndroid) {
        return (
            <div className="mode--browser">
                <Banner>
                    <div className="flex items-center">
                        <div className="flex flex-col items-start ml-0 space-x-0 md:items-center md:space-x-3 md:ml-2 md:flex-row">
                            <ParagraphSmall className="mb-3 mr-1 text-left text-black md:mb-0">
                                {alert.androidPromptInstructions}
                            </ParagraphSmall>
                            <div className="flex justify-start">
                                <Link
                                    href="/how-to/#android"
                                    aria-label="Learn more about the PWA"
                                    className="btn btn--outline-dark"
                                >
                                    Learn more
                                </Link>
                            </div>
                        </div>
                    </div>
                </Banner>
            </div>
        );
    }
    return null;
}

export default BannerPwaPrompt;
