'use client';
import { useState } from 'react';
import { Dialog, DialogBackdrop, DialogTitle } from '@headlessui/react';

export interface ModalClearMyTimelineProps {
    isOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export function ModalClearMyTimeline(props: ModalClearMyTimelineProps) {
    const { isOpen, onClose, onConfirm } = props;
    return (
        <Dialog open={isOpen} onClose={() => onClose()} className="fixed inset-0 z-40">
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <DialogBackdrop className="fixed inset-0 bg-black opacity-75" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="w-full max-w-md p-10 bg-ivory">
                    <DialogTitle>Are you sure you want to clear your timeline?</DialogTitle>
                    <div className="flex mt-6">
                        <button className="btn btn--primary" onClick={() => onConfirm()}>
                            Yes, I'm sure
                        </button>
                        <button className="btn" onClick={() => onClose()}>
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default ModalClearMyTimeline;
