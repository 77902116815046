'use client';
import { useMainNavDisplay } from '../context/mainNavDisplay';
import { Transition } from '@headlessui/react';
import Link from 'next/link';
import { IconCloseCircleBig } from '@moar/svg';
import { TypeSection } from '@moar/types';
import useResponsive from '../hooks/use-responsive/use-responsive';
import { useIsOnline } from '../context/online';

interface NavSectionProps {
    section: TypeSection;
}
function NavSection(props: NavSectionProps) {
    const { section } = props;
    const modeIsLight = section.colorIsTint === 'light';
    const { setIsOpen: setNavIsOpen } = useMainNavDisplay();
    return (
        <Link
            href={`/sections/#section-${section.slug}`}
            tabIndex={0}
            className="flex"
            onClick={() => setNavIsOpen(false)}
        >
            {section.navBackgroundImageMobile.url && (
                <div>
                    <div className="w-24 h-20 md:w-[264px] md:h-[211px]">
                        <img
                            alt={section.navBackgroundImageMobile.image_alt_text}
                            src={section.navBackgroundImageMobile.url}
                            className="object-cover w-24 h-20 md:w-[264px] md:h-[211px] rounded-l-sm "
                        />
                    </div>
                </div>
            )}
            <div
                className="flex items-center md:items-start md:py-10"
                style={{ backgroundColor: section.color.replace(', 1)', ', 0.8') }}
            >
                <div className={`ml-4 md:ml-12 md:mr-10 ${modeIsLight ? 'text-black' : 'text-white'}`}>
                    <h4 className="text-base font-medium">{section.date}</h4>
                    <h5 className="font-serif text-lg md:text-5xl">{section.title} </h5>
                </div>
            </div>
        </Link>
    );
}

export interface NavMainFullMobileProps {
    title: string;
    sections: TypeSection[];
    aboutPageTitle?: string | null;
    howToPageTitle?: string | null;
    glossaryPageTitle?: string | null;
    glossaryItemsCount: number;
}

// Includes tablet
export function NavMainFullMobile(props: NavMainFullMobileProps) {
    const {
        title,
        sections,
        aboutPageTitle = 'About',
        howToPageTitle = 'How To Use This Timeline',
        glossaryPageTitle = 'Glossary',
        glossaryItemsCount,
    } = props;
    const { isOpen, setIsOpen } = useMainNavDisplay();
    const { isDesktop } = useResponsive();
    const isOnline = useIsOnline();

    return (
        <Transition
            show={isOpen}
            appear={true}
            unmount={true}
            enter={`transition-opacity duration-400`}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave={`transition-opacity duration-400`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0 hidden"
        >
            <nav className="fixed top-0 z-50 block w-screen h-screen lg:hidden bg-midnight print:hidden">
                <div className="flex items-center h-16 px-6">
                    <button
                        aria-label={`Close section nav`}
                        className="flex items-center text-left group"
                        onClick={() => setIsOpen(false)}
                    >
                        <div className="w-9 h-9">
                            <IconCloseCircleBig />
                        </div>
                        <div className="ml-4 font-serif text-base text-white">{title}</div>
                    </button>
                </div>
                <div className={`h-screen pb-20 overflow-y-scroll`}>
                    <div className="px-5 mt-3">
                        <div className="flex flex-col">
                            {sections.map((section) => (
                                <div
                                    className={`relative group rounded-sm mb-4`}
                                    style={{ backgroundColor: section.color }}
                                    key={`nav-mobile-section-${section.position}`}
                                >
                                    <NavSection section={section} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="px-4 mt-4 mb-24">
                        <div className="flex w-full py-4 border-t border-white">
                            <ul className="flex flex-col mt-4 mb-8 space-y-5 md:space-y-0 md:space-x-12 md:flex-row">
                                <li className="text-sm font-bold text-white">
                                    <Link href="/how-to" tabIndex={0} onClick={() => setIsOpen(false)}>
                                        {howToPageTitle}
                                    </Link>
                                </li>
                                {glossaryItemsCount > 0 && (
                                    <li className="text-sm font-bold text-white">
                                        <Link href="/glossary" tabIndex={0} onClick={() => setIsOpen(false)}>
                                            {glossaryPageTitle}
                                        </Link>
                                    </li>
                                )}
                                <li className="text-sm font-bold text-white">
                                    <Link href="/about" tabIndex={0} onClick={() => setIsOpen(false)}>
                                        {aboutPageTitle}
                                    </Link>
                                </li>
                                {isOnline && (
                                    <li className="text-sm font-bold text-white">
                                        <Link
                                            href="https://www.amrevmuseum.org/"
                                            tabIndex={0}
                                            className="text-sm font-bold text-white"
                                            target="_blank"
                                        >
                                            Museum Website
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </Transition>
    );
}

export default NavMainFullMobile;
