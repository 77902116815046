'use client';
import { TypePagePwaProps } from '@moar/types';
import useResponsive from '../hooks/use-responsive/use-responsive';
import Layout from '../layout/layout';
import { PageHead } from '../page-head/page-head';
import { PwaLanding } from '../pwa-landing/pwa-landing';

export function PagePwa(props: TypePagePwaProps) {
    const { preview, timelineTitle, intro, pwaLanding } = props;
    const { isMobileDesign } = useResponsive();
    const { pwaSeoDescription, pwaSeoImage, pwaSeoKeywords, pwaSeoTitle } = pwaLanding;

    return (
        <Layout preview={preview}>
            <PageHead
                title={pwaSeoTitle}
                defaultTitle={timelineTitle}
                description={pwaSeoDescription}
                image={pwaSeoImage}
                keywords={pwaSeoKeywords}
                preloadImage={isMobileDesign ? intro.backgroundImageMobile?.url : intro.backgroundImageDesktop?.url}
            />

            <PwaLanding {...props} />
        </Layout>
    );
}

export default PagePwa;
