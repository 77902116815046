'use client';
import { useRouter } from 'next/router';
import { slugify } from '@moar/utils';
import { useCallback, useEffect, useState } from 'react';
import tippy, { hideAll } from 'tippy.js';

interface GenerateToolTipProps {
    term: string;
    def: string;
    url: string;
    isLight: boolean;
    sectionSlug?: string;
}
// generate html for outside of react tooltip
const generateToolTip = (props: GenerateToolTipProps) => {
    const { term, def, url: urlBase, isLight, sectionSlug } = props;
    const slugTerm = slugify(term);

    let url = urlBase;
    if (url && sectionSlug) {
        url = `${urlBase}?section=${sectionSlug}`;
    }

    return `
        <div class="tooltip infoPopup infoPopup--glossary ${isLight ? 'tooltip--light' : ''}">
            <p>${def}</p>
            ${
                url
                    ? `<a href="${url}#${slugTerm}" class="button"><span>Glossary</span><svg width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M5.36764 1.97306L12.7404 0.0339112C13.481 -0.160883 14.1567 0.515015 13.9618 1.25559L12.0214 8.62681C11.8727 9.19149 11.0419 9.08688 11.0378 8.50298L11.0134 4.96817C11.0123 4.79537 10.9892 4.62777 10.9468 4.46803L1.70712 13.7081C1.31661 14.0987 0.683442 14.0987 0.292909 13.7082C-0.097624 13.3176 -0.0976381 12.6845 0.292878 12.2939L9.53557 3.05084C9.37357 3.00715 9.20341 2.98329 9.02787 2.98203L5.49123 2.9566C4.90736 2.95241 4.80296 2.12158 5.36764 1.97306Z" fill="#004899"></path></svg></a>`
                    : ''
            }
        </div>`;
};

export interface UseGlossaryProps {
    ref: any;
    url?: string;
    isLight?: boolean;
    rerenderTrigger?: any;
    sectionSlug?: string;
}

export const useGlossary = (props: UseGlossaryProps) => {
    const { ref, url = '/glossary', isLight = false, rerenderTrigger, sectionSlug } = props;
    const router = useRouter();

    const initTooltips = useCallback(() => {
        if (!ref.current) return;
        const textArea = ref.current;
        const wordRefs = [...textArea.querySelectorAll('.wordDefine')];
        if (!wordRefs.length) return;

        wordRefs.forEach((elem) => {
            if (elem._tippy) return;

            tippy(elem, {
                interactive: true,
                content: 'some text',
                allowHTML: true,
                arrow: false,
                placement: 'bottom',
                animation: 'scale-subtle',
                appendTo: document.body,
                // for debug
                // trigger: 'click',
                onShow(instance) {
                    hideAll({ exclude: instance });
                },
                onCreate(instance) {
                    const content = generateToolTip({
                        term: elem.getAttribute('data-term'),
                        def: elem.getAttribute('data-def'),
                        url,
                        isLight,
                        sectionSlug,
                    });
                    instance.setContent(content);
                },
            });
        });
    }, [isLight, ref, url, sectionSlug]);

    useEffect(() => {
        initTooltips();
    }, [initTooltips, rerenderTrigger, router.pathname]);
};

export default useGlossary;
