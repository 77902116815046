import { IconArrow } from '@moar/svg';
import Link from 'next/link';
import qs from 'qs';

export function BackToTimelineNav() {
    const { section } = qs.parse(window.location.search.slice(1));
    let prevPage;

    if (section) {
        prevPage = `/sections#${section}`;
    } else if (
        document.referrer &&
        document.referrer.includes(window.location.protocol + '//' + window.location.hostname) &&
        !document.referrer.endsWith('/glossary')
    ) {
        prevPage = document.referrer;
    } else {
        prevPage = '/sections';
    }

    return (
        (<div className="print:hidden">
            <Link
                href={prevPage}
                className="flex items-baseline font-bold link--dark lg:justify-start group">

                <IconArrow color="blue" />
                <span className="ml-2 text-xs md:text-sm">Return to Timeline</span>

            </Link>
        </div>)
    );
}

export default BackToTimelineNav;
