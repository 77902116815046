'use client';
import { IconArrowCaret, LogoMoar } from '@moar/svg';
import { TypeIntro, TypePwaLanding, TypeTroubleshooting } from '@moar/types';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import AnimateFadeIn from '../animate-fade-in/animate-fade-in';
import { useIsOnline } from '../context/online';
import ErrorBoundaryApp from '../error-boundary-app/error-boundary-app';
import { Hxl, H2 } from '../headers/headers';
import useResponsive from '../hooks/use-responsive/use-responsive';
import useWindowSize from '../hooks/use-window-size/use-window-size';
import DeviceInstructions from '../device-instructions/device-instructions';
import { Intro } from '../intro/intro';
import useAddToHomescreenPrompt from '../hooks/use-add-to-homescreen-prompt/use-add-to-homescreen-prompt';
import TextHtml from '../text-html/text-html';

export interface PwaLandingProps {
    preview: boolean;
    intro: TypeIntro;
    linkToSite: string;
    title: string;
    paragraph: string;
    pwaLanding: TypePwaLanding;
    troubleshooting: TypeTroubleshooting;
}

export function PwaLanding(props: PwaLandingProps) {
    const windowSize = useWindowSize();
    // there is no ui here, but we need to call this hook to trigger the install
    // events that save the images to the cache
    useAddToHomescreenPrompt();
    const { preview, intro, linkToSite, title, paragraph, pwaLanding, troubleshooting } = props;
    const [isTruncated, setIsTruncated] = useState(false);
    const { isMobile } = useResponsive();
    const isOnline = useIsOnline();

    useEffect(() => {
        setIsTruncated(paragraph.length > 380);
    }, []);

    const { date, backgroundImageDesktop, backgroundImageMobile } = intro;

    const backgroundImage = isMobile ? backgroundImageMobile : backgroundImageDesktop;
    const borderHeight = windowSize && windowSize.height && windowSize.height > 0 ? windowSize.height - 20 : '';
    const calculateHeight = windowSize && windowSize.height && windowSize.height > 0 ? windowSize.height - 550 : '';
    const sectionHeight = windowSize && windowSize.height && windowSize.height > 0 ? `${calculateHeight}px` : '50vh';

    return (
        <ErrorBoundaryApp>
            <div className="mode--standalone">
                <Intro {...props} />
            </div>
            <div className="mode--browser">
                <AnimateFadeIn>
                    <div className="bg-blue sm:p-2.5" style={{ minHeight: windowSize.height }}>
                        <div className="sm:border sm:border-white" style={{ minHeight: borderHeight }}>
                            {preview && (
                                <div className="absolute right-5 bottom-16 md:bottom-20">
                                    <span className="ml-3 btn btn--primary">PREVIEW</span>
                                </div>
                            )}
                            <div
                                style={{
                                    backgroundImage: `url(${backgroundImage.url})`,
                                    backgroundSize: 'cover',
                                }}
                            >
                                <div className="p-7" style={{ backgroundColor: 'rgb(37 68 104 / 75%)' }}>
                                    <div className="px-0 mb-8">
                                        <LogoMoar />
                                    </div>
                                    <div className="z-30 flex justify-center md:top-8">
                                        <div className="flex flex-col">
                                            <div className="flex flex-col items-start max-w-4xl">
                                                {!!linkToSite && isOnline && (
                                                    <Link
                                                        href={linkToSite}
                                                        className="flex flex-row text-xs font-bold link--white group"
                                                    >
                                                        <IconArrowCaret direction="left" color="yellow" />
                                                        <span className="ml-2 group-hover:text-yellow-dark text-yellow">
                                                            Back to Museum Website
                                                        </span>
                                                    </Link>
                                                )}

                                                <TextHtml className="mt-4 text-white" html={date} />
                                                <Hxl textColor="text-white mt-4">{title}</Hxl>
                                                <TextHtml className="mt-4 text-white" html={paragraph} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="p-7" style={{ minHeight: sectionHeight }}> */}
                            <div className="p-7">
                                <DeviceInstructions pwaLanding={pwaLanding} />
                            </div>

                            {troubleshooting.troubleshootingCtaUrl && (
                                <div className="p-7 bg-midnight">
                                    <div className="z-30 flex justify-center md:top-8">
                                        <div className="flex flex-col">
                                            <div className="flex flex-col items-start max-w-4xl">
                                                <H2 textColor="text-white mb-4 mt-2">For troubleshooting assistance</H2>

                                                {troubleshooting.troubleshootingInstructions && (
                                                    <TextHtml
                                                        className="mb-4 text-white"
                                                        html={troubleshooting.troubleshootingInstructions}
                                                        linkColor="#85bacc"
                                                    />
                                                )}

                                                {isOnline && (
                                                    <Link
                                                        href={troubleshooting.troubleshootingCtaUrl}
                                                        className="flex flex-row text-sm font-bold border link--white group border-yellow"
                                                    >
                                                        <span className="p-2 group-hover:text-yellow-dark text-yellow">
                                                            {troubleshooting.troubleshootingCtaLabel ||
                                                                'More troubleshooting help'}
                                                        </span>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </AnimateFadeIn>
            </div>
        </ErrorBoundaryApp>
    );
}
