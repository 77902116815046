/* eslint-disable-next-line */
export interface IconMinusProps {}

export function IconMinus(props: IconMinusProps) {
    return (
        // <svg width="18" height="2" viewBox="0 0 18 2" fill="none">
        (<span className="w-4 h-2">
            <svg aria-hidden={true} className="w-4 h-2" viewBox="0 0 18 2" fill="none">
                <path
                    className="fill-blue"
                    d="M1 0H17C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2H1C0.447716 2 0 1.55228 0 1C0 0.447715 0.447716 0 1 0Z"
                ></path>
            </svg>
        </span>)
    );
}

export default IconMinus;
