'use client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { H3 } from '../headers/headers';
import TextHtml from '../text-html/text-html';

import { TypePwaLanding } from '@moar/types';
import { useContextData } from '../context/data';
import usePwaSupported from '../hooks/use-pwa-supported/use-pwa-supported';
import useUserAgent from '../hooks/use-user-agent/use-user-agent';

export interface DeviceInstructionsProps {
    pwaLanding: TypePwaLanding;
}

export function DeviceInstructions(props: DeviceInstructionsProps) {
    const { pwaLanding } = props;
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const data = useContextData();
    // const query = useSearchParams();
    const { query } = useRouter();
    const userAgent = useUserAgent();
    const {
        isSupported,
        isDesktopChrome,
        isDesktopEdge,
        isAndroidChrome,
        isAndroidFirefox,
        isSupportedSafari,
        isSupportedDesktop,
    } = usePwaSupported();

    const {
        androidChromeImage,
        androidChromeVideo,
        androidFirefoxImage,
        androidFirefoxVideo,
        desktopChromeImage,
        desktopChromeVideo,
        desktopEdgeImage,
        desktopEdgeVideo,
        instructionsAndroidChrome,
        instructionsAndroidFirefox,
        instructionsDesktopChrome,
        instructionsDesktopEdge,
        instructionsUnsupported,
        instructionsIosSafari,
        safariIosImage,
        safariIosVideo,
        titleAndroidChrome,
        titleAndroidFirefox,
        titleDesktopChrome,
        titleDesktopEdge,
        titleIosSafari,
        titleUnsupported,
    } = pwaLanding;

    useEffect(() => {
        if (!isSupported) {
            return;
        }

        if (!data) {
            setShowLoading(true);
        } else {
            setTimeout(() => {
                setShowLoading(false);
            }, 1000);
        }
    }, [isSupported, data]);

    if (!userAgent || query['standalone']) return null;

    if (showLoading) {
        return (
            <div className="z-30 flex justify-center">
                <div className="flex w-full max-w-4xl">
                    <div className="w-full font-serif font-bold text-white animate-pulse">
                        Loading (this may take a moment)...
                    </div>
                </div>
            </div>
        );
    }

    const getContent = () => {
        if (isDesktopChrome) {
            return {
                title: titleDesktopChrome,
                body: instructionsDesktopChrome,
                header: 'How to Install the Timeline PWA on Google Chrome (laptop or desktop)',
                image: desktopChromeImage,
                video: desktopChromeVideo,
            };
        }

        if (isDesktopEdge) {
            return {
                title: titleDesktopEdge,
                body: instructionsDesktopEdge,
                header: 'How to Install the Timeline PWA on Microsoft Edge (laptop or desktop)',
                image: desktopEdgeImage,
                video: desktopEdgeVideo,
            };
        }

        if (isAndroidChrome) {
            return {
                title: titleAndroidChrome,
                body: instructionsAndroidChrome,
                header: 'How to Install the Timeline PWA on Google Chrome (Android)',
                image: androidChromeImage,
                video: androidChromeVideo,
            };
        }

        if (isAndroidFirefox) {
            return {
                title: titleAndroidFirefox,
                body: instructionsAndroidFirefox,
                header: 'How to Install the Timeline PWA on Firefox (Android)',
                image: androidFirefoxImage,
                video: androidFirefoxVideo,
            };
        }

        if (isSupportedSafari) {
            return {
                title: titleIosSafari,
                body: instructionsIosSafari,
                header: 'How to Install the Timeline PWA on iOS Safari',
                image: safariIosImage,
                video: safariIosVideo,
            };
        }

        return null;
    };

    const content = getContent();

    if (isSupported) {
        return (
            <div className="z-30 flex justify-center">
                <div className="flex flex-col">
                    <div className="flex flex-col items-start max-w-4xl">
                        <H3 Tag="h2" className="mt-2 mb-8" textColor="text-white">
                            {content?.title || 'How to Download the App'}
                        </H3>

                        {content?.image && (
                            <img
                                className="mb-8"
                                src={content.image}
                                style={{ width: isSupportedDesktop ? '1920px' : '' }}
                            />
                        )}

                        {content?.video && (
                            <video className="w-full" controls>
                                <source src={content.video} type="video/mp4" />
                            </video>
                        )}

                        {content?.body && (
                            <TextHtml className="my-8 text-white" html={content.body} linkColor="#85bacc" />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-start max-w-4xl">
            <H3 Tag="h2" className="mt-2 mb-8" textColor="text-white">
                {titleUnsupported || 'Sorry, we currently do not support downloading the app on this browser.'}
            </H3>

            <TextHtml className="text-white" html={instructionsUnsupported} />
        </div>
    );
}

export default DeviceInstructions;
