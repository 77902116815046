'use client';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ContextMainNavDisplay = createContext<[boolean, (isOpen: boolean) => void]>([false, () => {}]);

export function ProviderMainNavDisplay({ children }: { children: ReactNode }) {
    const [isOpen, setIsOpen] = useState(false);
    return <ContextMainNavDisplay.Provider value={[isOpen, setIsOpen]}>{children}</ContextMainNavDisplay.Provider>;
}

export function useContextMainNavDisplay() {
    return useContext(ContextMainNavDisplay);
}

export function useMainNavDisplay() {
    const [isOpen, setOpen] = useContextMainNavDisplay();
    const router = useRouter();

    const setIsOpen = (isOpenNew: boolean) => {
        document.body.style.overflow = isOpenNew ? 'hidden' : 'auto';
        const hash = window.location.hash.replace('#', '');
        if (!isOpenNew && hash === 'nav-open') {
            router.replace('/sections');
        }
        setOpen(isOpenNew);
    };

    return { isOpen, setIsOpen };
}
