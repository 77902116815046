'use client';
import { TypeTimelineDate, TypeTimelineObject } from '@moar/types';
import ObjectCropped from '../object-cropped/object-cropped';
import ObjectDate from '../object-date/object-date';
import ObjectStandardFeatured from '../object-standard-featured/object-standard-featured';

export interface ItemTimelineProps {
    obj: TypeTimelineObject | TypeTimelineDate;
    color: string;
    mode?: 'light' | 'dark';
    sectionPosition: number;
    isLast: boolean;
}

export function ItemTimeline(props: ItemTimelineProps) {
    const { obj, color, mode, sectionPosition, isLast } = props;
    const { type } = obj;

    if (type === 'timeline-section-date') {
        return <ObjectDate isLast={isLast} obj={obj as TypeTimelineDate} color={color} mode={mode} />;
    }

    if (obj.object_type.startsWith('standard')) {
        return (
            <ObjectStandardFeatured
                objectType="standard"
                imagePlacement={obj.object_type === 'standard-image-left' ? 'left' : 'right'}
                obj={obj}
                color={color}
                mode={mode}
                sectionPosition={sectionPosition}
                isLast={isLast}
            />
        );
    }

    if (obj.object_type.startsWith('featured')) {
        return (
            <ObjectStandardFeatured
                objectType="featured"
                imagePlacement={obj.object_type === 'featured-image-left' ? 'left' : 'right'}
                obj={obj}
                color={color}
                mode={mode}
                sectionPosition={sectionPosition}
                isLast={isLast}
            />
        );
    }

    if (obj.object_type === 'cropped') {
        return <ObjectCropped obj={obj} color={color} mode={mode} sectionPosition={sectionPosition} isLast={isLast} />;
    }

    return null;
}

export default ItemTimeline;
