'use client';
import { IconClose, IconICircle } from '@moar/svg';
import { useState, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

export interface ImageCreditProps {
    creditText: string | null;
    mode?: 'intro' | 'section';
    color?: 'yellow' | 'midnight';
}

export function ImageCredit(props: ImageCreditProps) {
    const { creditText, color = 'midnight', mode = 'intro' } = props;
    const isIntro = mode === 'intro';
    const isSection = mode === 'section';
    const [isOpen, setIsOpen] = useState(false);

    const bgClasses = classNames({
        'bg-midnight': color === 'midnight',
        'bg-yellow': color === 'yellow',
    });
    const bgClassesTrigger = classNames({
        'bg-midnight hover:bg-blue': color === 'midnight',
        'bg-yellow hover:bg-yellow-dark': color === 'yellow',
    });
    const textColorCredit = classNames({
        'text-white': color === 'midnight',
        'text-black': color === 'yellow',
    });
    const textColorTrigger = classNames({
        'text-yellow': color === 'midnight',
        'text-midnight': color === 'yellow',
    });
    const positionClassesTrigger = classNames({
        'right-3 md:right-5': isIntro,
        'right-3': !isIntro,
    });
    const bottomClasses = classNames({
        'bottom-14 md:bottom-3': isSection && !isIntro,
        'bottom-3 md:bottom-5': !isSection && isIntro,
    });

    if (!creditText) return null;

    return (
        <div>
            <Transition
                show={isOpen}
                enter="z-[30] transform ease-out duration-300 transition"
                enterFrom="translate-y-4 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className={`absolute z-[29] xl:w-3/4 pl-3 pr-10 pt-4 pb-3 ${textColorCredit} text-sm text-left leading-normal rounded-sm max-w-[93%] md:max-w-md lg:max-w-xl ${bgClasses} ${positionClassesTrigger} ${bottomClasses}`}
                >
                    <button
                        tabIndex={0}
                        onClick={() => setIsOpen(false)}
                        className="absolute top-0 right-0 flex p-3 group"
                    >
                        <span className="w-[12px] h-[12px]">
                            <IconClose color={color === 'yellow' ? 'black' : 'yellow'} />
                        </span>
                    </button>
                    {creditText}
                </div>
            </Transition>
            <div className={`absolute z-[28] ${positionClassesTrigger} ${bottomClasses}`}>
                <button
                    tabIndex={0}
                    aria-label={`${isOpen ? 'Close' : 'Open'} image credit`}
                    onClick={() => setIsOpen((isOpen) => !isOpen)}
                    className={`flex items-center justify-center p-2.5 text-sm font-bold rounded-sm md:px-3 h-[36px] ${textColorTrigger} ${bgClassesTrigger} group`}
                >
                    <IconICircle color={color === 'midnight' ? 'yellow' : 'midnight'} />{' '}
                    <span className="hidden ml-2 xl:block" style={{ marginTop: '2px' }}>
                        Image Credit
                    </span>
                </button>
            </div>
        </div>
    );
}

export default ImageCredit;
