'use client';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { TypeTimelineGlossaryItem } from '@moar/types';

interface GlossaryNavProps {
    glossary: TypeTimelineGlossaryItem[];
}

function GlossaryNav(props: GlossaryNavProps) {
    const { glossary } = props;
    const router = useRouter();

    const allLetters = useMemo(() => {
        const alpha = Array.from(Array(26)).map((e, i) => i + 65);
        return alpha.map((x) => String.fromCharCode(x));
    }, []);

    const getPresentLetters = (Glossary: TypeTimelineGlossaryItem[]) => {
        const terms = Glossary.map((item) => item.title);
        const firstLetters = terms.map((term) => term.slice(0, 1).toUpperCase());
        return [...new Set(firstLetters)];
    };

    const presenLetters = getPresentLetters(glossary);

    // enables smooth scrolling to letter anchor and updates hash for back button
    const handleClick = (letter: string) => {
        const el = document.querySelector(`#${letter.toLowerCase()}`);
        el?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        document.documentElement.style.scrollBehavior = 'smooth';
        router.push({ pathname: `/glossary`, hash: letter.toLowerCase() }).catch((e) => {
            // workaround for https://github.com/vercel/next.js/issues/37362
            if (!e.cancelled) {
                throw e;
            }
        });
        setTimeout(() => {
            document.documentElement.style.scrollBehavior = 'auto';
        }, 1000);
    };

    const getIsLetterPresent = (letter: string) => {
        return presenLetters.includes(letter);
    };

    return (
        <ul className="flex justify-between pb-5 pl-5 mb-5 overflow-x-scroll scrollbars--light md:overflow-x-auto md:pb-0 md:pl-0 print:hidden">
            {allLetters.map((letter) => {
                const isLetterPresent = getIsLetterPresent(letter);
                return (
                    <li key={`Glossary-menu-${letter}`} className="">
                        {isLetterPresent && (
                            <button
                                onClick={() => handleClick(letter)}
                                className={`font-bold text-blue hover:bg-midnight hover:text-white h-[28px] w-[28px] flex justify-center items-center`}
                            >
                                {letter}
                            </button>
                        )}
                        {!isLetterPresent && (
                            <div
                                className={`font-bold text-blue-300 h-[28px] w-[28px] flex justify-center items-center opacity-70`}
                            >
                                {letter}
                            </div>
                        )}
                    </li>
                );
            })}
        </ul>
    );
}

export default GlossaryNav;
