'use client';
import React from 'react';
import { ErrorFallback } from '@moar/ui';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export type TypeProviders = {
    children: React.ReactNode;
};

Bugsnag.start({
    onError: (event) => {
        if (process.env.NODE_ENV === 'production') {
            return true;
        }
        return false;
    },
    apiKey: process.env['NEXT_PUBLIC_BUGSNAG_API'] || '',
    plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

export default function Error(props: TypeProviders) {
    const { children } = props;

    if (!ErrorBoundary) {
        return <>{children}</>;
    }
    return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
}
