'use client';
import { Transition } from '@headlessui/react';
import { BannerNavMain } from '../banner-nav-main/banner-nav-main';
import Footer from '../footer/footer';

import Layout from '../layout/layout';
import { NavFixedMobile } from '../nav-fixed-mobile/nav-fixed-mobile';
import NavMainFullDesktop from '../nav-main-full-desktop/nav-main-full-desktop';
import NavMainFullMobile from '../nav-main-full-mobile/nav-main-full-mobile';
import RelatedResources from '../related-resources/related-resources';
import { useEffect, useState } from 'react';
import { PageHead } from '../page-head/page-head';
import { TypeGlossaryPageProps, TypeTimelineGlossaryItem } from '@moar/types';
import { IconDownload, IconPrint } from '@moar/svg';
import ShareMenu from '../share-menu/share-menu';
import Glossary from '../glossary/glossary';
import BannerOffline from '../banner-offline/banner-offline';
import ButtonBackToTop from '../button-back-to-top/button-back-to-top';
import useScrollDirection from '../hooks/use-scroll-direction/use-scroll-direction';
import GlossaryNav from '../glossary-nav/glossary-nav';
import BackToTimelineNav from '../back-to-timeline-nav/back-to-timeline-nav';

interface GlossaryByLetter {
    letter: string;
    items: TypeTimelineGlossaryItem[];
}

export function PageGlossary({
    page,
    timelineTitle,
    preview,
    sections,
    offlineAlertBanner,
    glossary,
    relatedResources,
    aboutPageTitle,
    howToPageTitle,
}: TypeGlossaryPageProps) {
    const scrollDirection = useScrollDirection();
    const [isReady, setIsReady] = useState(false);
    const [itemsByLetter, setItemsByLetter] = useState<GlossaryByLetter[]>();

    useEffect(() => {
        setIsReady(true);
    }, []);

    useEffect(() => {
        // redirect to home if no glossary items
        if (glossary.length === 0) {
            // eslint-disable-next-line no-restricted-globals
            location.href = '/';
        }

        const itemsWorking: GlossaryByLetter[] = [];

        for (const [index, item] of glossary.entries()) {
            const firstLetter = item.title.slice(0, 1).toUpperCase();
            const group = itemsWorking.find((item) => item.letter === firstLetter);
            if (group) {
                group.items = [...group.items, item];
            } else {
                itemsWorking.push({ letter: firstLetter, items: [item] });
            }
        }

        setTimeout(() => {
            setItemsByLetter(itemsWorking);

            // Scroll to letter or word. Scroll didn't work on page load without this.
            const hash = window.location.hash;
            if (hash) {
                setTimeout(() => {
                    document.querySelector(hash)?.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }, 2000);
    }, [glossary]);

    const { glossaryPageTitle, glossarySeoTitle, glossarySeoImage, glossarySeoDescription, glossaryPDF } = page;

    if (!itemsByLetter) {
        return (
            <div className="absolute bg-ivory w-full h-full">
                <div className="relative flex justify-center top-1/2">
                    <span className="font-serif text-2xl animate-pulse">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <Layout preview={preview}>
            <PageHead
                defaultTitle={timelineTitle}
                title={glossarySeoTitle}
                image={glossarySeoImage}
                description={glossarySeoDescription}
            />
            <BannerOffline offlineAlertBanner={offlineAlertBanner} />
            <BannerNavMain preview={preview} title={timelineTitle} />
            <NavMainFullDesktop
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossary.length}
            />
            <NavMainFullMobile
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossary.length}
            />
            <div className="relative">
                <Transition
                    show={isReady}
                    appear={true}
                    enter={`transition-opacity duration-500`}
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave={`transition-opacity duration-500`}
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="bg-ivory print:bg-transparent">
                        <div className="grid grid-cols-12 px-6 pt-24 md:px-10 lg:container md:pt-28 lg:px-0 print:pt-0">
                            <div className="col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3">
                                <div className="flex justify-start mb-2">
                                    <BackToTimelineNav />
                                </div>
                                <div className="flex items-center justify-between pb-4 mb-5 border-b border-black">
                                    <h1 className="font-serif text-5xl md:text-7xl">{glossaryPageTitle}</h1>
                                    <div className="flex space-x-5 print:hidden">
                                        <div className="relative">
                                            <ShareMenu buttonMode="button" />
                                        </div>
                                        <button
                                            aria-label="Print Glossary"
                                            onClick={() => window.print()}
                                            className="w-[36px] h-[36px] bg-white rounded-sm flex items-center justify-center hover:bg-ivory-dark"
                                        >
                                            <IconPrint />
                                        </button>
                                        {glossaryPDF && (
                                            <a
                                                aria-label="Download Glossary as PDF"
                                                href={glossaryPDF}
                                                target="_blank"
                                                className="w-[36px] h-[36px] bg-white rounded-sm flex items-center justify-center hover:bg-ivory-dark"
                                                rel="noreferrer"
                                            >
                                                <IconDownload color="black" />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="px-0 md:grid md:grid-cols-12 lg:container md:px-10 lg:px-0">
                            <div className="col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3">
                                <div className="px-0">
                                    <GlossaryNav glossary={glossary} />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 px-6 pb-6 lg:container md:px-10 lg:px-0">
                            <div className="col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3">
                                <Glossary itemsByLetter={itemsByLetter} />
                            </div>
                        </div>
                    </div>
                </Transition>
                <ButtonBackToTop bgColor={`rgba(245, 242, 237, 1)`} isVisible={scrollDirection === 'down'} />
            </div>
            <RelatedResources relatedResources={relatedResources} />
            <NavFixedMobile />
            <Footer padForBottomNav={true} />
        </Layout>
    );
}

export default PageGlossary;
