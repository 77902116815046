'use client';
import classNames from 'classnames';
import { HTMLProps } from 'react';

interface PProps {
    mode?: 'light' | 'dark';
    children: React.ReactNode;
    className?: string;
}

export type ParagraphProps = PProps & HTMLProps<HTMLParagraphElement>;

export function ParagraphLarge(props: ParagraphProps) {
    const { children, className, ...rest } = props;
    return (
        <p className={`text-base-plus md:text-lg font-sans ${className}`} {...rest}>
            {children}
        </p>
    );
}

export function Paragraph(props: ParagraphProps) {
    const { children, className, ...rest } = props;
    return (
        <p className={`text-base font-sans font-normal ${className}`} {...rest}>
            {children}
        </p>
    );
}

export function ParagraphSmall(props: ParagraphProps) {
    const { children, className, ...rest } = props;
    return (
        <p className={`text-sm md:text-base font-sans font-normal ${className}`} {...rest}>
            {children}
        </p>
    );
}

export function ParagraphXSmall(props: ParagraphProps) {
    const { children, className, ...rest } = props;
    return (
        <p className={`text-xs font-sans ${className}`} {...rest}>
            {children}
        </p>
    );
}
