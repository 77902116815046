'use client';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { IconClose, IconStar } from '@moar/svg';
import { H4 } from '../headers/headers';
import Link from 'next/link';
import { useContextMyTimelineDisplay } from '../context/myTimelineDisplay';
import useMyTimelineItems from '../context/myTimelineItems';
import { useMyTimelineTitle } from '../context/myTimelineTitle';
import useFocus from '../hooks/use-focus/use-focus';
import ModalClearMyTimeline from '../modal-clear-my-timeline/modal-clear-my-timeline';

/* eslint-disable-next-line */
export interface SlideOverMyTimelineProps {}

export function SlideOverMyTimeline(props: SlideOverMyTimelineProps) {
    const [inputRef, setFocus] = useFocus();
    const [isOpen, setIsOpen] = useContextMyTimelineDisplay();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const { items, removeItem, clearItems } = useMyTimelineItems();
    const [title, setTitle] = useMyTimelineTitle();

    const setToClose = () => {
        setIsOpen(false);
    };

    const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.select();
    };

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setFocus();
            }, 500);
        }
    }, []);

    return (
        <Transition show={isOpen}>
            <Dialog as="div" className="relative z-40" onClose={setToClose}>
                {isConfirmOpen && (
                    <ModalClearMyTimeline
                        isOpen={isConfirmOpen}
                        onConfirm={() => {
                            setIsConfirmOpen(false);
                            clearItems();
                        }}
                        onClose={() => setIsConfirmOpen(false)}
                    />
                )}
                <TransitionChild
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="fixed inset-0 transition-opacity bg-black bg-opacity-75"
                        onClick={() => setIsOpen(false)}
                    />
                </TransitionChild>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none md:pl-20">
                            <TransitionChild
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <DialogPanel className="w-screen max-w-md pointer-events-auto">
                                    <div className="flex flex-col h-full py-8 overflow-y-scroll shadow-xl bg-ivory">
                                        <div className="px-4 sm:px-10">
                                            <div className="flex items-start justify-between">
                                                <DialogTitle className="font-serif text-lg md:text-2xl">
                                                    My Timeline
                                                </DialogTitle>
                                                <div className="flex items-center ml-3 h-7">
                                                    <button
                                                        type="button"
                                                        className="flex p-2 rounded-sm group focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2"
                                                        onClick={() => setIsOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <span className=" w-[12px] h-[12px]">
                                                            <IconClose />
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {!items ||
                                            (items.length === 0 && (
                                                <div className="relative flex-1 px-4 mt-10 space-y-3 sm:px-10">
                                                    <p>
                                                        You haven’t started a timeline yet. Select the star icon{' '}
                                                        <span className="mr-1 w-[14px] h-[16px] inline-block">
                                                            <IconStar color={'midnight'} />
                                                        </span>
                                                        next to an object to begin adding to your timeline. Once your
                                                        timeline is complete, you can download it as a PDF.
                                                    </p>
                                                </div>
                                            ))}
                                        {items && items.length > 0 && (
                                            <div className="relative flex-1 px-4 mt-3 sm:px-10">
                                                <div className="mb-6">
                                                    <div className="relative">
                                                        <input
                                                            ref={inputRef}
                                                            value={title}
                                                            placeholder={'Name Your Timeline'}
                                                            name="my_timeline_name"
                                                            onInput={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                                setTitle(e.target.value);
                                                            }}
                                                            type="text"
                                                            className="w-full px-1 pt-3 pb-2 font-sans text-base leading-5 border-b border-black rounded-none placeholder:text-black bg-ivory focus:outline-none focus:ring-2 focus:ring-blue"
                                                            onFocus={handleFocus}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="mt-5">
                                                        <Link
                                                            href="/my-timeline"
                                                            onClick={() => setToClose()}
                                                            className="btn btn--primary btn--full"
                                                        >
                                                            View My Timeline
                                                        </Link>
                                                    </div>
                                                    <div className="mt-5">
                                                        <button
                                                            onClick={() => setIsConfirmOpen(true)}
                                                            className="btn btn--outline-dark btn--full"
                                                        >
                                                            Clear All
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="my-6 space-y-6">
                                                    {items?.map((item) => (
                                                        <div key={`my-timeline-drawer-${item.id}`} className="">
                                                            <div className="flex">
                                                                <div className="my-timeline-thumb w-[60px] mr-4">
                                                                    <div className="my-timeline-thumb--frame">
                                                                        <div className="my-timeline-thumb--helper"></div>
                                                                        <Link
                                                                            href={`/objects/${item.slug}`}
                                                                            onClick={() => setToClose()}
                                                                        >
                                                                            <img src={item.image?.url} />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="mb-1 text-xs">{item.date}</p>
                                                                    <h5>{item.title}</h5>
                                                                    <button
                                                                        className="flex items-center group"
                                                                        onClick={() => removeItem(item)}
                                                                    >
                                                                        <span className="w-[8px] h-[8px] md:w-[10px] md:h-[10px]">
                                                                            <IconClose color="blue" />
                                                                        </span>
                                                                        <span className="mt-[2px] ml-2 font-bold text-blue hover:text-midnight text-xs md:text-sm ">
                                                                            Remove
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default SlideOverMyTimeline;
