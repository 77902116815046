'use client';
import Link from 'next/link';
import { useEffect } from 'react';
import { ToastContentProps } from 'react-toastify';
import useFocusLink from '../hooks/use-focus-link/use-focus-link';

export function ToastMyTimeline(props: ToastContentProps) {
    const { closeToast, toastProps } = props;
    const [toastRef, setFocus] = useFocusLink();

    // removed focus because tooltip on toggle icon was hiding
    // useEffect(() => {
    //     setTimeout(() => {
    //         setFocus();
    //     }, 1000);
    // }, []);

    return (
        <div
            tabIndex={0}
            className="block space-y-3 bg-midnight md:flex md:items-center md:justify-center md:space-y-0 md:space-x-5"
        >
            <span className="font-sans text-sm text-white ">Object has been added to your timeline</span>
            <Link
                href="/my-timeline"
                ref={toastRef}
                tabIndex={0}
                aria-label="Go to My Timeline"
                className="btn btn--primary"
            >
                {' '}
                My Timeline
            </Link>
            <button
                tabIndex={0}
                aria-label="Close alert"
                className="text-sm font-bold text-yellow hover:text-yellow-dark "
                onClick={() => closeToast && closeToast()}
            >
                Dismiss
            </button>
        </div>
    );
}

export default ToastMyTimeline;
