'use client';
import { useEffect, useRef, useState } from 'react';
import { Dialog, DialogBackdrop, DialogTitle } from '@headlessui/react';
import useResponsive from '../hooks/use-responsive/use-responsive';
import { formatBytes, getImagesToCache, GTMEvent } from '@moar/utils';
import { useContextData } from '../context/data';
import { useIsOnline } from '../context/online';
import usePwaSupported from '../hooks/use-pwa-supported/use-pwa-supported';
import usePersistedState from '../hooks/use-persisted-state/use-persisted-state';
import { IconCloseBox } from '@moar/svg';
import useInterval from '../hooks/use-interval/use-interval';

declare const navigator: any;

/* eslint-disable-next-line */
export interface ModalImagesCacheProps {}

// used to be just for safari, but now for all since install isn't working automatically

export function ModalImagesCache(props: ModalImagesCacheProps) {
    const { isSupportedSafari, isSupportedDesktop, isSupportedAndroid } = usePwaSupported();
    const { isMobileDesign } = useResponsive();
    const isOnline = useIsOnline();
    const data = useContextData();
    const [status, setStatus] = useState<'loading' | 'success' | null>(null);
    const [cacheImages, setCacheImages] = useState<null | number>(null);
    const [cacheImageSize, setCacheImageSize] = useState<null | number>(null);
    const [modalShown, setModalShown] = usePersistedState('safari-modal-shown', false);
    const [displayMode, setDisplayMode] = useState<string | null>(null);
    const isStandaloneRef = useRef<boolean>(false);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [showReadyError, setReadyError] = useState<boolean>(false);

    useEffect(() => {
        let displayMode = 'browser';
        // hacking this
        const navigator = window.navigator as any;
        if (navigator['standalone'] as any) {
            displayMode = 'standalone';
        }
        if (window.matchMedia('(display-mode: standalone)').matches) {
            displayMode = 'standalone';
        }
        setDisplayMode(displayMode);

        const standalone = window.matchMedia('(display-mode: standalone)');
        standalone.onchange = (e) => {
            if (e.matches && isStandaloneRef.current === false) {
                setDisplayMode('standalone');
                isStandaloneRef.current = true;
            }
        };
    }, []);

    // https://usehooks-ts.com/react-hook/use-interval
    // In safari, poll to see precache files are loaded
    // listener only fires after after all that is done
    useInterval(
        () => {
            if (!window.serwist) return;
            window.serwist.messageSW({
                command: 'log',
                message: 'ready ping',
                action: 'READY_PING',
            });
        },
        // Delay in milliseconds or null to stop it
        !isReady ? 2000 : null
    );

    useEffect(() => {
        if (!window.serwist) return;
        const handleMessage = (event: any) => {
            console.log(event);
            // alert(JSON.stringify(event.data.payload));
            if (event.data.type === 'READY_PING_ANSWER') {
                setIsReady(true);
            }
            if (event.data.type === 'CACHE_IMAGES_DONE') {
                setCacheImages(event.data.payload.count);
                setStatus('success');
            }
        };

        window.serwist.addEventListener('message', handleMessage);

        return function cleanup() {
            window.serwist.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        const modalTimeout = setTimeout(() => {
            setReadyError(true);
        }, 5000);
        return () => {
            clearTimeout(modalTimeout);
        };
    }, []);

    const handleCacheImages = () => {
        if (!data || !window.serwist) return;
        setStatus('loading');
        setCacheImages(null);
        const urls = getImagesToCache({ data, isMobile: isMobileDesign });

        window.serwist.messageSW({
            command: 'log',
            message: 'cache images',
            action: 'CACHE_IMAGES',
            payload: urls,
        });
    };

    const handleClose = () => {
        GTMEvent({ eventName: 'app_install' });
        setModalShown(true);
    };

    console.log({
        modalShown,
        isSupportedSafari,
        isSupportedDesktop,
        isSupportedAndroid,
        displayMode,
    });

    // if modal is shown on safari desktop, images will install, but will be lost in standalone and they will have to be redownloaded
    // display mode is
    const showModal =
        !modalShown && displayMode === 'standalone' && (isSupportedSafari || isSupportedDesktop || isSupportedAndroid);

    if (!showModal) return null;

    return (
        <Dialog open={!modalShown} onClose={() => handleClose()} className="fixed inset-0 z-[100]">
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <DialogBackdrop className="fixed inset-0 bg-black opacity-75" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="relative w-full px-6 py-6 overflow-y-scroll min-h-[100px] md:max-w-md bg-ivory">
                    {showReadyError && !isReady && (
                        <>
                            <div className="absolute z-50 right-3 top-3">
                                <button onClick={() => handleClose()}>
                                    <IconCloseBox />
                                    <span className="sr-only">Close Modal</span>
                                </button>
                            </div>
                            <DialogTitle className="font-serif text-2xl transition-all md:text-4xl">
                                This is taking longer than it should
                            </DialogTitle>
                            <div className="flex flex-col items-center justify-center">
                                <p className="mt-2 text-sm text-center">
                                    We recommend you close the app and try again. If you don't, the app might not work
                                    properly offline. If this persists, please contact us.
                                </p>
                            </div>
                        </>
                    )}
                    {!isReady && (
                        <div className="flex flex-col items-center justify-center">
                            <div className="font-serif font-bold animate-pulse text-base-plus">Loading App ...</div>
                            <p className="mt-2 text-sm text-center">
                                This may take a while depending on your connection speed. This only happens the first
                                time the app is opened.
                            </p>
                            {/* <p>DEBUG:</p>
                            <ul>
                                <li>modalShown: {modalShown}</li>
                                <li>displayMode: {displayMode}</li>
                                <li>isSupportedSafari: {isSupportedSafari}</li>
                                <li>isSupportedDesktop: {isSupportedDesktop}</li>
                                <li>isSupportedAndroid: {isSupportedAndroid}</li>
                            </ul> */}
                        </div>
                    )}
                    {isReady && (
                        <>
                            <div className="absolute z-50 right-3 top-3">
                                <button onClick={() => handleClose()}>
                                    <IconCloseBox />
                                    <span className="sr-only">Close Modal</span>
                                </button>
                            </div>
                            <DialogTitle className="font-serif text-2xl transition-all md:text-4xl">
                                Option to Load Images
                            </DialogTitle>

                            {isOnline && (
                                <div className="flex items-center">
                                    <div className="mt-4">
                                        <div>
                                            <h2 className="font-sans text-base">
                                                Would you like to load the images so you can use the app offline?
                                            </h2>
                                            <p className="my-2 text-sm">
                                                We recommend connecting to wifi instead of using cellular data before
                                                loading the images.
                                            </p>
                                            {status === null && (
                                                <div className="flex items-center justify-between mt-4 space-x-2">
                                                    <button
                                                        className="btn btn--primary"
                                                        onClick={() => handleCacheImages()}
                                                    >
                                                        Load images
                                                    </button>
                                                    <button
                                                        className="btn btn--outline-dark"
                                                        onClick={() => handleClose()}
                                                    >
                                                        No, Thanks
                                                    </button>
                                                </div>
                                            )}
                                            {status === 'loading' && (
                                                <div className="my-4 font-serif font-bold animate-pulse">
                                                    Loading (this may take a while)...
                                                </div>
                                            )}
                                            {status === 'success' && (
                                                <div className="my-4 font-bold">
                                                    <div className="mb-4">
                                                        Done! Loaded {cacheImages && cacheImages} images{' '}
                                                        {/* {cacheImageSize !== null ? `${formatBytes(cacheImageSize)}` : ''} */}
                                                    </div>
                                                    <button
                                                        className="btn btn--outline-dark"
                                                        onClick={() => handleClose()}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isOnline && (
                                <div>
                                    <p className="mt-2">You must be online to load images for offline use.</p>
                                    <button className="btn btn--outline-dark" onClick={() => handleClose()}>
                                        Okay
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Dialog>
    );
}

export default ModalImagesCache;
