'use client';
import { TypePersistedTimelineItem } from '@moar/types';
import { createContext, ReactNode, useContext, useState } from 'react';
import usePersistedState from '../hooks/use-persisted-state/use-persisted-state';

type TypeContextMyTimelineItems = {
    items: [] | TypePersistedTimelineItem[] | undefined;
    setPersistedValue: (newValue: any) => void;
};

const initialValues = {
    items: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setPersistedValue: () => {},
};

export const ContextMyTimelineItems = createContext<TypeContextMyTimelineItems>(initialValues);

export function ProviderMyTimelineItems({ children }: { children: ReactNode }) {
    const [items, setPersistedValue] = usePersistedState<[] | TypePersistedTimelineItem[]>('my-timeline-items', []);
    return (
        <ContextMyTimelineItems.Provider value={{ items, setPersistedValue }}>
            {children}
        </ContextMyTimelineItems.Provider>
    );
}

export function useContextMyTimelineItems() {
    return useContext(ContextMyTimelineItems);
}

export function useMyTimelineItems() {
    const { items, setPersistedValue } = useContextMyTimelineItems();

    const addItem = (newItem: TypePersistedTimelineItem) => {
        console.log('add');

        const ids = items?.map((item) => item.id);
        if (ids?.includes(newItem.id)) return;

        const newItems = [...(items || []), newItem];
        const sortedBySection = newItems.sort((a, b) => {
            return a.sectionPosition - b.sectionPosition || a.position - b.position;
        });
        setPersistedValue(sortedBySection);
    };

    const removeItem = (newItem: TypePersistedTimelineItem) => {
        console.log('remove');

        if (!items || items.length === 0) return;
        console.log(items.filter((item) => item['id'] !== newItem.id));
        setPersistedValue(items.filter((item) => item['id'] !== newItem.id));
    };

    const clearItems = () => {
        setPersistedValue([]);
    };

    return { items, addItem, removeItem, clearItems };
}

export default useMyTimelineItems;
