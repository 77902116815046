'use client';
import { TypeSection } from '@moar/types';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useSearchState } from '../context/search';
import { useVerticalNavSectionOverlap } from '../context/verticalNavSectionOverlap';
interface DotProps {
    isCurrent: boolean;
    sectionIsLight: boolean;
    index: number;
}

function Dot(props: DotProps) {
    const { currentSectionIndex, doesOverlapIntro } = useVerticalNavSectionOverlap();

    const { isCurrent, sectionIsLight, index } = props;
    const isCurrentOrPast = isCurrent || currentSectionIndex > index;

    const bgColor = classNames({
        'bg-black': isCurrentOrPast && sectionIsLight && !doesOverlapIntro,
        'bg-white': (isCurrentOrPast && !sectionIsLight) || doesOverlapIntro,
    });

    const opacity = classNames({
        'opacity-100': isCurrentOrPast,
        'opacity-50': !isCurrentOrPast,
    });
    const borderColor = classNames({
        'border-black group-hover:bg-black group-hover:opacity-100': sectionIsLight && !doesOverlapIntro,
        'border-white group-hover:bg-white group-hover:opacity-100': !sectionIsLight || doesOverlapIntro,
    });
    const size = classNames({
        'w-3 h-3 left-0 left-[2px]': !isCurrent,
        'w-[14px] h-[14px] left-[1px]': isCurrent,
    });
    return (
        <span
            className={`${opacity} ${borderColor} ${bgColor} ${size}  transition-all content-[' '] rounded-full border-2 absolute top-0`}
        ></span>
    );
}

interface ProgressLinesProps {
    isLast: boolean;
    progress: number;
    sectionIsLight: boolean;
}
function ProgressLine(props: ProgressLinesProps) {
    const { isLast, progress, sectionIsLight } = props;
    const { doesOverlapIntro } = useVerticalNavSectionOverlap();

    const borderColor = classNames({
        'border-black': sectionIsLight && !doesOverlapIntro,
        'border-white': !sectionIsLight || doesOverlapIntro,
    });
    return (
        <span
            className={`absolute top-[12px] left-[7px] ${borderColor} ${!isLast ? `border-l-2 z-10` : ''}`}
            style={{ height: `${progress}%` }}
        ></span>
    );
}

interface LineProps {
    isLast: boolean;
    sectionIsLight: boolean;
}
function Line(props: LineProps) {
    const { doesOverlapIntro } = useVerticalNavSectionOverlap();
    const { isLast, sectionIsLight } = props;

    const color = classNames({
        'border-black': sectionIsLight && !doesOverlapIntro,
        'border-white': !sectionIsLight || doesOverlapIntro,
    });

    return (
        <span
            className={`${color} transition-all h-32 absolute ${
                !isLast ? 'border-l-2  absolute left-[7px] top-[12px] h-[116px] opacity-50 z-0 ' : ''
            }`}
        ></span>
    );
}
interface TextBlockProps {
    date: string;
    title: string;
    sectionIsLight: boolean;
    isCurrent: boolean;
}
function TextBlock(props: TextBlockProps) {
    const { date, title, sectionIsLight, isCurrent } = props;
    const { doesOverlapIntro } = useVerticalNavSectionOverlap();
    const textColor = classNames({
        'text-black': sectionIsLight && !doesOverlapIntro,
        'text-white': !sectionIsLight || doesOverlapIntro,
    });
    const position = classNames({
        flex: isCurrent,
        'hidden group-hover:flex': !isCurrent,
    });
    return (
        <div className={`${position} flex-col h-32 ml-6 space-y-1`}>
            <span className={`transition-all text-sm ${textColor}`}>{date}</span>
            <span className={`transition-all text-sm font-bold ${textColor}`}>{title}</span>
        </div>
    );
}

export interface TimelineNavVerticalProps {
    sections: TypeSection[];
}
// Used for desktop and mobile although vertical nav is hidden on mobile, still used for calculating current section
export function TimelineNavVertical(props: TimelineNavVerticalProps) {
    const { sections } = props;
    const { currentSection, currentSectionProgress, currentSectionIndex } = useVerticalNavSectionOverlap();
    const router = useRouter();
    const { isActive: isSearchActive } = useSearchState();

    return (
        <nav>
            <div
                id="vertical-nav"
                className={`hidden ${
                    isSearchActive ? 'lg:hidden' : 'lg:block'
                } fixed left-6 z-20 w-40 top-[64px] md:top-[55%] translate-y-0 md:translate-y-[-55%]`}
            >
                {sections.map((section, i) => {
                    const isCurrent = currentSection?.slug === section.slug;
                    const sectionIsLight = currentSection?.colorIsTint === 'light';
                    const isLast = i === sections.length - 1;
                    const progress = isCurrent ? currentSectionProgress : i < currentSectionIndex ? 100 : 0;

                    return (
                        <div
                            key={`vertical-nav-section-${section.position}`}
                            className={`relative transition-all h-32 overflow-hidden pl-2`}
                        >
                            <button
                                aria-label={`Go to section: ${section.title}`}
                                tabIndex={0}
                                className={`transition-all group text-left focus:shadow-none focus:outline-transparent focus:ring-transparent focus:ring-opacity-0 focus:ring-offset-0 focus:outline-0 focus:ring-0`}
                                onClick={() => {
                                    const element = document.getElementById(`section-${section.slug}`);
                                    element?.scrollIntoView({ behavior: 'smooth' });
                                    document.documentElement.style.scrollBehavior = 'smooth';
                                    router
                                        .push({ pathname: `/sections`, hash: `section-${section.slug}` })
                                        .catch((e) => {
                                            // workaround for https://github.com/vercel/next.js/issues/37362
                                            if (!e.cancelled) {
                                                throw e;
                                            }
                                        });
                                    setTimeout(() => {
                                        document.documentElement.style.scrollBehavior = 'auto';
                                    }, 1000);
                                }}
                            >
                                <Dot isCurrent={isCurrent} sectionIsLight={sectionIsLight} index={i} />
                                <Line isLast={isLast} sectionIsLight={sectionIsLight}></Line>
                                <ProgressLine
                                    isLast={isLast}
                                    progress={progress}
                                    sectionIsLight={sectionIsLight}
                                ></ProgressLine>
                                <TextBlock
                                    title={section.title}
                                    date={section.date}
                                    sectionIsLight={sectionIsLight}
                                    isCurrent={isCurrent}
                                />
                            </button>
                        </div>
                    );
                })}
            </div>
        </nav>
    );
}

export default TimelineNavVertical;
