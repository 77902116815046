'use client';
import { useRef } from 'react';

export function useFocus() {
    const htmlElRef = useRef<HTMLInputElement>(null);
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus] as const;
}
export default useFocus;
