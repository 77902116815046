'use client';

import { TypeTimeline } from '@moar/types';
import { createContext, ReactNode, useContext, useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import { fetcher } from '../../lib/fetcher';

const apiUrl = process.env['NEXT_PUBLIC_API_URL_TIMELINE_AMREV'] || '';

export const ContextData = createContext<TypeTimeline | undefined>(undefined);

export function ProviderData({ children }: { children: ReactNode }) {
    const { data, error } = useSWRImmutable(apiUrl, fetcher);

    return <ContextData.Provider value={data}>{children}</ContextData.Provider>;
}

export function useContextData() {
    return useContext(ContextData);
}
