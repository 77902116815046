'use client';
import { useMainNavDisplay } from '../context/mainNavDisplay';
import { Transition } from '@headlessui/react';
import Link from 'next/link';
import { IconCloseCircleBig } from '@moar/svg';
import { TypeSection } from '@moar/types';
import classNames from 'classnames';
import useResponsive from '../hooks/use-responsive/use-responsive';
import { useIsOnline } from '../context/online';

interface NavSectionProps {
    section: TypeSection;
}
function NavSection(props: NavSectionProps) {
    const { section } = props;
    const modeIsLight = section.colorIsTint === 'light';
    const { setIsOpen: setNavIsOpen } = useMainNavDisplay();
    return (
        <div className={`block w-full h-full`}>
            {section.navBackgroundImageDesktop.url && (
                <img
                    alt={section.navBackgroundImageDesktop.image_alt_text}
                    src={section.navBackgroundImageDesktop.url}
                    className="absolute top-0 left-0 object-cover w-screen h-screen-full-almost md:h-[80vh]"
                />
            )}
            <Link
                href={`/sections/#section-${section.slug}`}
                tabIndex={0}
                onClick={() => setNavIsOpen(false)}
                className={`block w-full h-full group`}
            >
                <div
                    className="absolute bottom-0 left-0 flex items-center w-full duration-1000 transition-height h-36 group-hover:h-full"
                    style={{ backgroundColor: section.color.replace(', 1)', ', 0.85') }}
                >
                    <div className={`p-10 font-serif ${modeIsLight ? 'text-black' : 'text-white'}`}>
                        <h4 className="text-base-plus">{section.date}</h4>
                        <h5 className="flex items-baseline mt-1 text-4xl group-focus:underline">
                            {section.title}{' '}
                            {/* <span className="ml-3">
                            <IconArrowCaret color={modeIsLight ? 'black' : 'white'} />
                        </span> */}
                        </h5>
                    </div>
                </div>
            </Link>
        </div>
    );
}

interface NavMainFullDesktopProps {
    title: string;
    sections: TypeSection[];
    aboutPageTitle?: string | null;
    howToPageTitle?: string | null;
    glossaryPageTitle?: string | null;
    glossaryItemsCount: number;
}

export function NavMainFullDesktop(props: NavMainFullDesktopProps) {
    const {
        title,
        sections,
        aboutPageTitle = 'About',
        howToPageTitle = 'How To Use This Timeline',
        glossaryPageTitle = 'Glossary',
        glossaryItemsCount,
    } = props;
    const { isOpen, setIsOpen } = useMainNavDisplay();
    const { isDesktop } = useResponsive();
    const isOnline = useIsOnline();

    const widthClass = classNames({
        'w-1/2': sections.length === 2,
        'w-1/3': sections.length === 3,
        'w-1/4': sections.length === 4,
        'w-1/5': sections.length === 5,
        'w-1/6': sections.length === 6,
    });

    if (!isDesktop) return null;

    return (
        <Transition
            show={isOpen}
            appear={true}
            unmount={true}
            enter={`transition-opacity duration-500`}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave={`transition-opacity duration-500`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0 hidden"
        >
            <nav className="fixed top-0 z-50 w-screen h-screen print:hidden">
                <div className="absolute z-20 left-6 top-6">
                    <button aria-label={`Close section nav`} className="group" onClick={() => setIsOpen(false)}>
                        <IconCloseCircleBig />
                    </button>
                </div>
                <div className="w-screen h-[80vh]">
                    <div className="flex flex-row h-full">
                        {sections.map((section) => (
                            <div
                                className={`relative h-full overflow-hidden group ${widthClass}`}
                                style={{ backgroundColor: section.color }}
                                key={`nav-desktop-section-${section.position}`}
                            >
                                <NavSection section={section} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="bg-midnight h-[20vh] w-screen">
                    <div className="flex flex-row items-center h-full px-10">
                        <div className="w-full">
                            <div className="flex items-center justify-between pb-4 border-b border-white">
                                <div className="font-serif text-4xl text-white">{title}</div>
                                <ul className="flex space-x-14">
                                    <li className="text-sm font-bold text-white hover:text-yellow whitespace-nowrap">
                                        <Link
                                            href="/how-to"
                                            onClick={() => {
                                                setIsOpen(false);
                                            }}
                                        >
                                            {howToPageTitle}
                                        </Link>
                                    </li>
                                    {glossaryItemsCount && (
                                        <li className="text-sm font-bold text-white hover:text-yellow">
                                            <Link
                                                href="/glossary"
                                                onClick={() => {
                                                    setIsOpen(false);
                                                }}
                                            >
                                                {glossaryPageTitle}
                                            </Link>
                                        </li>
                                    )}
                                    <li className="text-sm font-bold text-white hover:text-yellow">
                                        <Link
                                            href="/about"
                                            onClick={() => {
                                                setIsOpen(false);
                                            }}
                                        >
                                            {aboutPageTitle}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            {isOnline && (
                                <div className="mt-6 text-right">
                                    <a
                                        href="https://www.amrevmuseum.org/"
                                        className="text-sm font-bold text-white hover:text-yellow"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Museum of the American Revolution
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </Transition>
    );
}

export default NavMainFullDesktop;
