'use client';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ContextIsOnline = createContext<[boolean, (isOpen: boolean) => void]>([true, () => {}]);

export function ProviderIsOnline({ children }: { children: ReactNode }) {
    const [isOnlne, setIsOnline] = useState(true);
    return <ContextIsOnline.Provider value={[isOnlne, setIsOnline]}>{children}</ContextIsOnline.Provider>;
}

const useContextIsOnline = () => useContext(ContextIsOnline);

export const useIsOnline = (): boolean => {
    const [isOnline, setIsOnline] = useContextIsOnline();
    useEffect(() => {
        const setOnline = () => {
            setIsOnline(true);
        };
        const setOffline = () => {
            setIsOnline(false);
        };
        if (typeof window !== 'undefined' && 'ononline' in window && 'onoffline' in window) {
            setIsOnline(window.navigator.onLine);
            if (!window.ononline) {
                window.addEventListener('online', setOnline);
            }
            if (!window.onoffline) {
                window.addEventListener('offline', setOffline);
            }
        }
        return function cleanup() {
            window.removeEventListener('offline', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return isOnline;
};
