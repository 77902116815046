'use client';
import { IconShare, IconShareLink } from '@moar/svg';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useIsOnline } from '../context/online';

// text used on detail page, button used on glossary
export interface ShareMenuProps {
    buttonMode?: 'text' | 'button';
    title?: string;
}

export function ShareMenu(props: ShareMenuProps) {
    const { buttonMode = 'text', title } = props;
    const isOnline = useIsOnline();
    const [facebookUrl, setFacebookUrl] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [emailUrl, setEmailUrl] = useState('');

    useEffect(() => {
        const title = encodeURIComponent(document.title);
        const url = encodeURIComponent(window.location.href);

        // Used hook to make sure component is rendered on UI before looking for window.location or error
        setFacebookUrl(`https://facebook.com/sharer/sharer.php?u=${url}`);
        setTwitterUrl(`https://twitter.com/intent/tweet/?text=${title}%20via%20@amrevmuseum&url=${url}`);
        setEmailUrl('mailto:?subject='.concat(title) + '&body='.concat(url));
    }, [title]);

    if (!isOnline) return null;

    return (
        <div>
            <Menu>
                {buttonMode === 'text' && (
                    <Menu.Button className="flex items-center group" aria-label="Share">
                        <IconShareLink />
                        <span className="mt-1 ml-2 text-sm font-bold text-blue group-hover:text-midnight">Share</span>
                    </Menu.Button>
                )}
                {buttonMode === 'button' && (
                    <Menu.Button
                        className="w-[36px] h-[36px] bg-white rounded-sm flex items-center justify-center hover:bg-ivory-dark"
                        aria-label="Share"
                    >
                        <IconShare />
                    </Menu.Button>
                )}

                <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-40 w-40 mt-2 origin-top-right bg-white border shadow-lg border-ash ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="">
                            <ul className="divide-y divide-[#ccc]">
                                <Menu.Item>
                                    <li className="">
                                        <a
                                            aria-label="Share via Twitter"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="block px-4 py-3 text-base-plus hover:bg-[#ecf0f5]"
                                            href={twitterUrl}
                                        >
                                            Twitter
                                        </a>
                                    </li>
                                </Menu.Item>
                                <Menu.Item>
                                    <li className="">
                                        <a
                                            aria-label="Share via Facebook"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="block px-4 py-3 text-base-plus hover:bg-[#ecf0f5]"
                                            href={facebookUrl}
                                        >
                                            Facebook
                                        </a>
                                    </li>
                                </Menu.Item>
                                <Menu.Item>
                                    <li className="">
                                        <a
                                            aria-label="Share via Email"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="block px-4 py-3 text-base-plus hover:bg-[#ecf0f5]"
                                            href={emailUrl}
                                        >
                                            Email
                                        </a>
                                    </li>
                                </Menu.Item>
                            </ul>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}

export default ShareMenu;
