'use client';
import { IconMagnifyingGlass, IconMenu, IconStar, LogoMoar } from '@moar/svg';
import Link from 'next/link';
import { useContextSearchDisplay } from '../context/searchDisplay';
import { Transition } from '@headlessui/react';
import { useElementPositions } from '../context/elementPositions';
import useResponsive from '../hooks/use-responsive/use-responsive';
import { useSearchState } from '../context/search';
import useScrollDirection from '../hooks/use-scroll-direction/use-scroll-direction';
import { useMainNavDisplay } from '../context/mainNavDisplay';
import { useRouter } from 'next/router';
import { useContextMyTimelineDisplay } from '../context/myTimelineDisplay';
import useMyTimelineItems from '../context/myTimelineItems';
import classNames from 'classnames';
import { useContextModalCacheDisplay } from '../context/modalCacheDisplay';
import Tippy from '@tippyjs/react';

export interface BannerNavMainProps {
    // on section page, nav gets hidden and replaced by sticky section title
    // other pages should just display nav
    persistNav?: boolean;
    disableSearch?: boolean;
    preview: boolean;
    title: string;
    hideForPrint?: boolean;
}

export function BannerNavMain(props: BannerNavMainProps) {
    const [, setIsModalOpen] = useContextModalCacheDisplay();
    const { persistNav = true, disableSearch = true, preview, title, hideForPrint = false } = props;
    const { isOpen: isNavOpen, setIsOpen: setNavIsOpen } = useMainNavDisplay();
    const [, setSearchIsOpen] = useContextSearchDisplay();
    const [, setMyTimelineIsOpen] = useContextMyTimelineDisplay();
    const { items } = useMyTimelineItems();
    const { isMobile, isMobileDesign } = useResponsive();
    const scrollDirection = useScrollDirection();
    const { isActive: isSearchActive } = useSearchState();
    const { firstSectionIntroInView } = useElementPositions();
    const router = useRouter();
    const count = items?.length;
    const isMyTimelinePage = router.pathname === '/my-timeline';

    const showNav = () => {
        if (persistNav || !isMobileDesign) return true;
        if (scrollDirection === 'up') return true;
        if (!isSearchActive) return firstSectionIntroInView;
        return true;
    };

    const handleExit = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        window.serwist.messageSW({
            command: 'log',
            message: 'clear cache',
            action: 'CLEAR_CACHE',
        });
        // eslint-disable-next-line no-restricted-globals
        router.push(`/api/exit-preview?redirectTo=${location.href}`);
    };

    const printClasses = classNames({
        '': hideForPrint,
        'print:block': !hideForPrint,
    });

    return (
        <>
            <div className={`hidden mt-5 mb-5 ${printClasses}`}>
                <div className="flex items-center">
                    <LogoMoar mode="light" />
                    <h1 className="ml-8 font-serif text-4xl">{title}</h1>
                </div>
            </div>
            <Transition
                show={showNav()}
                appear={true}
                unmount={true}
                enter={`transition-opacity duration-500`}
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave={`transition-opacity duration-500`}
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <nav className="fixed top-0 z-40 w-full h-16 bg-midnight print:hidden">
                    <div className="flex items-center justify-between h-16 px-6 lg:px-10">
                        <Tippy
                            className={`${isMobile ? 'hidden' : ''} infoPopup--menu`}
                            content={'Menu'}
                            placement={'bottom-start'}
                            arrow={false}
                        >
                            <button
                                tabIndex={0}
                                aria-label={`${isNavOpen ? 'Close' : 'Open'} section nav`}
                                className="mr-2 group"
                                onClick={() => {
                                    setNavIsOpen(true);
                                }}
                            >
                                <div className="flex items-center">
                                    <div className="w-9 h-9">
                                        <IconMenu />
                                    </div>

                                    <span className="ml-4 font-serif text-base text-left text-white betterhover:group-hover:text-yellow">
                                        {title}
                                    </span>
                                </div>
                            </button>
                        </Tippy>
                        {preview && (
                            <div>
                                <Link href="/" className="ml-3 btn btn--primary" onClick={(e) => handleExit(e)}>
                                    EXIT PREVIEW
                                </Link>
                            </div>
                        )}
                        <div className="hidden md:block">
                            <div className="flex space-x-12">
                                <button
                                    disabled={disableSearch}
                                    className={`flex py-1 items-center text-xs text-white group betterhover:hover:text-yellow disabled:hidden border-yellow border-b-2 ${
                                        isSearchActive ? 'border-yellow' : 'border-transparent'
                                    }`}
                                    onClick={() => setSearchIsOpen(true)}
                                >
                                    <span className={`w-[12px] h-[12px]`}>
                                        <IconMagnifyingGlass color={isSearchActive ? 'yellow' : 'white'} />
                                    </span>
                                    <span
                                        className={`ml-2 betterhover:group-hover:text-yellow mt-[2px] ${
                                            isSearchActive ? 'text-yellow' : 'text-white'
                                        }`}
                                    >
                                        Search & Filter
                                    </span>
                                </button>
                                <button
                                    className={`flex items-center text-xs group betterhover:hover:text-yellow py-1 border-b-2 ${
                                        isMyTimelinePage ? 'text-yellow' : 'text-white'
                                    } ${
                                        isMyTimelinePage ? 'text-yellow border-yellow' : 'text-white border-transparent'
                                    }`}
                                    onClick={() => setMyTimelineIsOpen(true)}
                                >
                                    <span className={`w-[12px] h-[14px]`}>
                                        <IconStar color={isMyTimelinePage ? 'yellow' : 'white'} solid={false} />{' '}
                                    </span>
                                    <span className="mt-[2px] ml-2">My Timeline</span>
                                    {items && items.length > 0 && (
                                        <span className={`text-yellow pl-2 mt-[2px] ml-2 border-l border-blue`}>
                                            {count}
                                        </span>
                                    )}
                                </button>
                                <button
                                    className={`mode--standalone flex items-center text-xs group betterhover:hover:text-yellow py-1 border-b-2 text-white border-transparent`}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    <span className={`w-[12px] h-[14px]`}>
                                        <span className="h-[14px]">{`</>`}</span>
                                    </span>
                                    <span className="mt-[2px] ml-2">Settings</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            </Transition>
        </>
    );
}
