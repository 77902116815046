'use client';
import { IconArrowCircle } from '@moar/svg';
import { Transition } from '@headlessui/react';

export interface ButtonBackToTopProps {
    isVisible: boolean;
    bgColor: string;
}

export function ButtonBackToTop(props: ButtonBackToTopProps) {
    const { isVisible, bgColor } = props;
    const handleClick = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <div className="absolute bottom-0 hidden w-full h-20 md:block" style={{ backgroundColor: bgColor }}></div>
            <div
                className={`hidden pt-[10px] md:block sticky ml-[50%] translate-x-[-30%] md:translate-x-[-10%] z-[11] transition-all bottom-20 md:bottom-0 animate-fade-in h-20`}
            >
                <Transition
                    show={isVisible}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                >
                    <button
                        className="flex items-center pl-6 bg-white rounded-full group"
                        onClick={() => handleClick()}
                    >
                        <span className="mr-2 text-sm font-bold text-midnight group-hover:text-blue">Back to top</span>
                        <IconArrowCircle />
                    </button>
                </Transition>
            </div>
        </>
    );
}

export default ButtonBackToTop;
