import { useEffect, useRef, useState } from 'react';
// removed this with upgrade to next 14. Couldn't get type declaration to work.
// import { Untabbable } from 'react-untabbable';
import { TypeTimelineObjectImage } from '@moar/types';
import CarouselLegend from '../../carousel-legend/carousel-legend';
import Hotspot from '../../hotspot/hotspot';

export interface CarouselSlideProps {
    accountForThumbs: boolean;
    bgColor: string;
    clickZoom: () => void;
    isActive: boolean;
    mode?: 'dark' | 'light';
    slide: TypeTimelineObjectImage;
}

export function CarouselSlide(props: CarouselSlideProps) {
    const { accountForThumbs, bgColor, clickZoom, isActive, mode, slide } = props;
    const { hotspots } = slide;
    const [imageWidth, setImageWidth] = useState<number | null>(null);
    const [imageHeight, setImageHeight] = useState<number | null>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const updateImageDimensions = () => {
        if (!imgRef.current) return;
        setImageWidth(imgRef.current.getBoundingClientRect().width);
        setImageHeight(imgRef.current.getBoundingClientRect().height);
    };

    useEffect(() => {
        if (!imgRef) return;
        updateImageDimensions();
        window.addEventListener('resize', updateImageDimensions);
        return () => window.removeEventListener('resize', updateImageDimensions);
    }, [imgRef]);

    return (
        // <Untabbable disabled={isActive}>
        <div style={{ backgroundColor: bgColor }} className="relative rounded-sm">
            <div className="slide__frame">
                {hotspots.length > 0 && imageWidth && imageWidth !== 0 && (
                    <div className="z-40 hotspot__container-outer">
                        <div
                            className="hotspot__container-inner "
                            style={{
                                width: `${imageWidth}px`,
                                height: `${imageHeight}px`,
                            }}
                        >
                            {hotspots
                                ? hotspots.map((hotspot, index) => {
                                      return <Hotspot hotspot={hotspot} key={`${hotspot}-${hotspot.x}-${hotspot.y}`} />;
                                  })
                                : null}
                        </div>
                    </div>
                )}
                <span className="slide__helper"></span>
                <img ref={imgRef} alt="" src={slide.url} onLoad={() => updateImageDimensions()} />
            </div>

            <CarouselLegend
                accountForThumbs={accountForThumbs}
                clickZoom={clickZoom}
                creditText={slide.image_credit}
                mode={mode}
            />
        </div>
        // </Untabbable>
    );
}

export default CarouselSlide;
