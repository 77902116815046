'use client';
import { TypeAudio, TypeTimelineObject, TypeTimelineObjectImage } from '@moar/types';
import Link from 'next/link';
import { H3, useIsOnline } from '../../index';
import { IconArrow, IconDownload } from '@moar/svg';
import ObjectNav from '../object-nav/object-nav';
import ObjectCarousel from '../object-carousel/object-carousel';
import ShareMenu from '../share-menu/share-menu';
import Footer from '../footer/footer';
import AudioBlock from '../audio-block/audio-block';
import { AnimateFadeIn } from '../animate-fade-in/animate-fade-in';
import { useEffect, useState } from 'react';
import ModalZoom from '../modal-zoom/modal-zoom';
import TextHtml from '../text-html/text-html';
import { useContextModalZoom } from '../context/modalZoom';
import ButtonToggleMyTimeline from '../button-toggle-my-timeline/button-toggle-my-timeline';
import BlockVideo from '../block-video/block-video';

export interface ObjectDetailProps {
    page: TypeTimelineObject;
    prevObjectSlug?: string | undefined;
    nextObjectSlug?: string | undefined;
    sectionTitle: string;
    sectionColor: string;
    sectionPosition: number;
    mode: 'light' | 'dark';
}

export function ObjectDetail(props: ObjectDetailProps) {
    const [isOnlineOnLoad, setIsOnlineOnLoad] = useState(true);
    const { isOpen: isOpenZoom, setIsOpen: setIsOpenZoom } = useContextModalZoom();

    const isOnline = useIsOnline();
    const { page, prevObjectSlug, nextObjectSlug, sectionColor, sectionTitle, sectionPosition, mode } = props;
    const { title, childObjects, description, date, slug, tags, tombstone, position } = page;

    const images = childObjects
        .filter((obj) => obj.type === 'timeline-section-images')
        .sort((a, b) => (a.position < b.position ? -1 : a.position > b.position ? 1 : 0)) as TypeTimelineObjectImage[];
    const videos = childObjects.filter((obj) => obj.type === 'timeline-section-videos');
    const pdfs = childObjects.filter((obj) => obj.type === 'timeline-section-pdfs');
    const audios = childObjects.filter((obj) => obj.type === 'timeline-section-audios') as TypeAudio[];
    const sources = childObjects.filter((obj) => obj.type === 'timeline-section-sources');

    useEffect(() => {
        setIsOnlineOnLoad(isOnline);
    }, []);

    const handleZoom = () => {
        setIsOpenZoom(true);
    };

    return (
        <>
            <ModalZoom />
            <div className="relative bg-ivory">
                <AnimateFadeIn speed="fast">
                    <div
                        className="absolute z-10 w-full h-[70%] md:h-[79%] lg:h-[79%] xl:h-[79%]"
                        style={{ backgroundColor: sectionColor.replace(', 1)', ', 0.7') }}
                    ></div>
                </AnimateFadeIn>

                <div className="relative grid grid-cols-12 gap-0 px-6 mx-auto md:px-10 xl:container md:gap-8 z-8 xl:px-0">
                    <div className="hidden lg:block">
                        <ObjectNav prevSlug={prevObjectSlug} nextSlug={nextObjectSlug} colorIsTint={mode === 'light'} />
                    </div>
                    <div className="z-10 col-span-12 mt-20 md:mt-24 lg:col-span-8 lg:col-start-3">
                        <div className="mb-4">
                            <Link
                                href={`/sections#${slug}`}
                                className={`${
                                    mode === 'light' ? 'link--dark' : 'link--white'
                                } flex items-baseline justify-center mb-2 lg:justify-start group font-bold`}
                            >
                                <IconArrow color={mode === 'light' ? 'blue' : 'white'} />
                                <span className="ml-2 text-xs md:text-sm">Return to {sectionTitle}</span>
                            </Link>

                            <AnimateFadeIn key={`title-${slug}`} speed="fast">
                                <h1
                                    className={`${
                                        mode === 'light' ? 'text-black' : 'text-white'
                                    } font-serif text-3xl text-center md:text-8xl lg:text-left`}
                                >
                                    {title}
                                </h1>
                                <p
                                    className={`${
                                        mode === 'light' ? 'text-black' : 'text-white'
                                    } mt-2 font-sans text-sm font-normal text-center md:text-base lg:text-left`}
                                >
                                    {date}
                                </p>
                            </AnimateFadeIn>
                        </div>

                        <div className="relative">
                            {images.length > 0 && (
                                <ObjectCarousel
                                    mode={mode}
                                    slug={slug}
                                    key={`carousel-${slug}`}
                                    images={
                                        isOnline
                                            ? (images as TypeTimelineObjectImage[])
                                            : ([images[0]] as TypeTimelineObjectImage[])
                                    }
                                    color={sectionColor}
                                    clickZoom={handleZoom}
                                />
                            )}

                            <div
                                className={`flex justify-center mt-4 space-x-4 md:absolute md:right-0 ${
                                    images.length <= 1 || !isOnline ? 'md:-bottom-12' : 'md:bottom-5'
                                }`}
                            >
                                <div>
                                    <ButtonToggleMyTimeline
                                        mode={'light'}
                                        item={{
                                            id: page.id,
                                            title,
                                            date,
                                            image: images[0],
                                            position,
                                            sectionPosition,
                                            slug,
                                        }}
                                    />
                                </div>

                                {isOnline && (
                                    <div className={`md:z-40`}>
                                        <div className="relative flex justify-center md:justify-end">
                                            <ShareMenu title={title} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-10 bg-ivory min-h-auto lg:min-h-[50vh]">
                <div className="relative z-0 grid grid-cols-12 gap-0 px-6 mx-auto md:px-10 xl:container md:gap-8 z-8 xl:px-0">
                    <div
                        className={`z-10 col-span-12 lg:col-span-8 lg:col-start-3 ${
                            images.length <= 1 || !isOnline ? 'mt-6' : ''
                        }`}
                    >
                        {description && (
                            <div className="mt-2 mb-6 md:mt-10 prose-general">
                                <TextHtml html={description} />
                                {/* <div dangerouslySetInnerHTML={{ __html: description }}></div> */}
                            </div>
                        )}
                        {tags && tags.length > 0 && (
                            <div>
                                <ul className="flex flex-wrap">
                                    {tags.map((tag) => (
                                        <li key={tag.id} className="flex mr-2 mb-2 h-[36px]">
                                            <Link
                                                href={`/sections?tags=${encodeURIComponent(tag.title)}`}
                                                aria-label={`Search timeline with filter: ${tag.title}`}
                                                className={`px-5 py-3 rounded-full flex items-center text-sm bg-yellow hover:bg-yellow-dark text-black`}
                                            >
                                                {tag.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {tombstone && (
                            <div className="mt-8 md:mt-10">
                                <H3 Tag="h2">Details</H3>
                                <div
                                    className="mt-4 prose-details"
                                    dangerouslySetInnerHTML={{ __html: tombstone }}
                                ></div>
                            </div>
                        )}
                        {videos.length > 0 && isOnline && (
                            <>
                                {videos.map(
                                    (video) =>
                                        !!video.video_url && (
                                            <BlockVideo
                                                key={video.id}
                                                title={video.video_title}
                                                url={video.video_url}
                                                transcript={video.video_transcription}
                                                credit={video.video_credit}
                                            />
                                        )
                                )}
                            </>
                        )}
                        {(pdfs.length > 0 || audios.length > 0) && isOnline && (
                            <div className="mt-8 md:mt-10">
                                <H3 Tag="h2">Explore More</H3>
                                <div className="mt-4">
                                    {pdfs.length > 0 &&
                                        pdfs.map((pdf) => (
                                            <div key={pdf.url} className="mb-4 rounded-sm p-7 bg-ivory-dark">
                                                {!!pdf.title && (
                                                    <h3 className="font-sans text-base font-bold">{pdf.title}</h3>
                                                )}
                                                {!!pdf.description && (
                                                    <div
                                                        className="mt-2 mb-2 prose-media"
                                                        dangerouslySetInnerHTML={{ __html: pdf.description }}
                                                    ></div>
                                                )}
                                                {!!pdf.url && (
                                                    <a
                                                        className="flex font-bold text-md text-sans text-blue hover:text-black group"
                                                        href={pdf.url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <span className="mr-2">Download PDF</span> <IconDownload />
                                                    </a>
                                                )}
                                            </div>
                                        ))}

                                    {audios.length > 0 &&
                                        audios.map((oneAudio) => <AudioBlock audio={oneAudio} key={oneAudio.id} />)}
                                </div>
                            </div>
                        )}
                        {sources.length > 0 && isOnline && (
                            <div className="mt-8 md:mt-10">
                                <H3 Tag="h2">Sources</H3>
                                <ul className="mt-4">
                                    {sources.map((source) => (
                                        <li key={source.link} className="mb-2">
                                            {!!source.link && (
                                                <a
                                                    className="text-base underline text-sans text-blue hover:text-black"
                                                    href={source.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {source.link_title}
                                                </a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-end lg:hidden">
                <ObjectNav
                    prevSlug={prevObjectSlug}
                    nextSlug={nextObjectSlug}
                    colorIsTint={mode === 'light'}
                    color={sectionColor}
                />
            </div>
            <Footer padForBottomNav={true} />
        </>
    );
}

export default ObjectDetail;
