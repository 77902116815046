'use client';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
export interface AnimateFadeInProps {
    children: React.ReactNode;
    speed?: 'fast' | 'normal';
}

export function AnimateFadeIn(props: AnimateFadeInProps) {
    const { children, speed = 'normal' } = props;

    const speedClass = classNames({
        'duration-300': speed === 'fast',
        'duration-1000': speed === 'normal',
    });

    return (
        <Transition
            as={'div'}
            show={true}
            appear={true}
            enter={`transition-opacity ${speedClass}`}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave={`transition-opacity ${speedClass}`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {children}
        </Transition>
    );
}

export default AnimateFadeIn;
