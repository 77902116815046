'use client';
import { TypeTimelineObject, TypeTimelineObjectImage } from '@moar/types';
import classNames from 'classnames';
import { useInView } from 'react-cool-inview';
import { useSearchState } from '../context/search';

import Link from 'next/link';
import ButtonToggleMyTimeline from '../button-toggle-my-timeline/button-toggle-my-timeline';
import { H5 } from '../headers/headers';
import useResponsive from '../hooks/use-responsive/use-responsive';
import { Paragraph } from '../paragraphs/paragraphs';
import { useRouter } from 'next/router';
import TextHtml from '../text-html/text-html';
import { IconArrow } from '@moar/svg';

export interface ObjectCroppedProps {
    obj: TypeTimelineObject;
    color: string;
    mode?: 'light' | 'dark';
    sectionPosition: number;
    isLast: boolean;
}

export function ObjectCropped(props: ObjectCroppedProps) {
    const { isMobile } = useResponsive();
    const { isActive } = useSearchState();
    const router = useRouter();
    const { observe, inView } = useInView({
        // fixes object not loading if page is scrolled and object is not fully on screen
        delay: 200,
        // removed because some objects were not loading
        // unobserveOnEnter: true,
        rootMargin: `${isMobile ? '-200px' : '0px'} 0px`,
    });
    const { obj, color, mode, sectionPosition, isLast } = props;
    const { date, title, timeline_excerpt, childObjects, slug, object_type, tags, position } = obj;

    const textColor = classNames({
        'text-white group-hover:text-yellow transition-all': mode === 'dark',
        'text-black group-hover:text-blue transition-all': mode === 'light',
    });

    const linkColor = classNames({
        'text-white hover:text-yellow': mode === 'dark',
        'text-blue hover:text-blue': mode === 'light',
    });

    const borderColor = classNames({
        'border-dark opacity-30': mode === 'light',
        'border-white': mode === 'dark',
    });
    const images = childObjects
        .filter((obj) => obj.type === 'timeline-section-images')
        .sort((a, b) => (a.position < b.position ? -1 : a.position > b.position ? 1 : 0)) as TypeTimelineObjectImage[];

    // add object hash to route to make back button go back to object
    const handleGoToObject = () => {
        router
            .push({
                pathname: `/objects/${slug}`,
                hash: `${slug}`,
            })
            .catch((e) => {
                // workaround for https://github.com/vercel/next.js/issues/37362
                if (!e.cancelled) {
                    throw e;
                }
            });
    };

    return (
        <div
            className={`object-cropped scroll-mt-16 ${
                isLast ? '' : position === 1 || isActive ? 'mb:-[25px] lg:mb-[50px]' : 'md:my-[25px] lg:my-[50px]'
            }  ${inView ? 'animate-fade-in' : 'opacity-0'}`}
            id={`${slug}`}
            ref={observe}
        >
            <div style={{ backgroundColor: color.replace(', )', ', 1)') }}>
                <div className="py-8 md:pl-8 md:py-0 2xl:container">
                    <div className="grid grid-cols-12 gap-0 md:gap-10">
                        <div className="col-span-12 col-start-1 lg:col-span-10 lg:col-start-3">
                            <div className={`grid grid-cols-10 gap-0 md:grid-cols-8 lg:grid-cols-10 md:gap-10`}>
                                {/* TEXT */}
                                <div className={`px-5 col-span-10 md:px-0 md:col-span-4 lg:col-span-5`}>
                                    {isMobile && images[0].url && (
                                        <div className={`mb-2 rounded-sm`}>
                                            <div className={`relative flex items-center justify-center h-date`}>
                                                <Link
                                                    href={`/objects/${slug}`}
                                                    onClick={() => handleGoToObject()}
                                                    aria-label={`View details about ${title}`}
                                                    className={`${linkColor} group transition-all`}
                                                >
                                                    <img
                                                        alt={images[0].image_alt_text}
                                                        src={images[0].url}
                                                        className="max-h-date"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                    <div className={`flex flex-col justify-center md:h-full md:mx-6 lg:mx-0`}>
                                        <div className="mb-1">
                                            <ButtonToggleMyTimeline
                                                justIcon={true}
                                                mode={mode}
                                                item={{
                                                    id: obj.id,
                                                    title,
                                                    date,
                                                    image: images[0],
                                                    position,
                                                    sectionPosition,
                                                    slug,
                                                }}
                                            />
                                        </div>
                                        <Link
                                            href={`/objects/${slug}`}
                                            onClick={() => handleGoToObject()}
                                            aria-label={`View details about ${title}`}
                                            className={`${linkColor} group transition-all`}
                                        >
                                            <div className="space-y-1">
                                                <H5 Tag="span" textColor={textColor}>
                                                    {date}
                                                </H5>
                                                <h3
                                                    className={`text-3xl font-serif md:text-4xl transition-all ${textColor}`}
                                                >
                                                    {title}
                                                </h3>
                                                <h4
                                                    className={`pt-1 ${textColor} mb-3 text-base font-sans font-normal transition-all`}
                                                >
                                                    {timeline_excerpt}
                                                </h4>
                                            </div>
                                            <div className="my-4">
                                                <div className={`border-t ${borderColor}`}></div>
                                            </div>
                                            <span className={`text-sm font-bold flex items-center`}>
                                                View Details{' '}
                                                <span className="ml-2">
                                                    <IconArrow
                                                        direction="right"
                                                        color={mode === 'dark' ? 'white' : 'blue'}
                                                    />
                                                </span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                {/* IMAGE */}
                                <div className={`col-span-10 md:col-span-4 lg:col-span-5`}>
                                    {!isMobile && images[0].url && (
                                        <div className={`relative md:h-[425px] lg:h-[525px] xl:h-[659px]`}>
                                            <Link
                                                href={`/objects/${slug}`}
                                                onClick={() => handleGoToObject()}
                                                aria-label={`View details about ${title}`}
                                                className={`${linkColor} group transition-all`}
                                            >
                                                <span className="overflow-hidden image-wrapper">
                                                    <img
                                                        alt={images[0].image_alt_text}
                                                        src={images[0].url}
                                                        className="object-cover h-full transition-all duration-500 group-hover:scale-[1.05]"
                                                    />
                                                </span>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ObjectCropped;

// mx-0 overflow-hidden md:mb-25px lg:mb-[50px]
//   md:my-[25px] lg:my-[50px]
