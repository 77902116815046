'use client';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';

import { useContextModalZoom } from '../context/modalZoom';
import { IconCloseBox, IconMinus, IconPlus } from '@moar/svg';
import PanZoomController from './pan-zoom';
import Hotspot from '../hotspot/hotspot';

/* eslint-disable-next-line */
export interface ModalZoomProps {}

export function ModalZoom(props: ModalZoomProps) {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const { isOpen, setIsOpen, image } = useContextModalZoom();
    const [panZoom, setPanZoom] = useState<PanZoomController>();
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setIsImageLoaded(false);
    }, [image]);

    if (!image) {
        return null;
    }

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog onClose={() => setIsOpen(false)} className="relative z-50">
                <div className="fixed inset-0 flex items-center justify-center">
                    <div id="modal--zoom" className="w-full h-full">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0"
                        >
                            <DialogPanel className="relative w-full h-full overflow-hidden bg-ivory">
                                <div className="absolute z-50 right-3 top-3">
                                    <button
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                    >
                                        <IconCloseBox />
                                        <span className="sr-only">Close Modal</span>
                                    </button>
                                </div>

                                <div className="absolute z-50 right-3 bottom-3">
                                    <button
                                        className="btn btn--primary w-[36px] h-[36px] flex justify-center items-center mb-2"
                                        onClick={() => panZoom?.zoomIn()}
                                    >
                                        <IconPlus />
                                        <span className="sr-only">Zoom In</span>
                                    </button>

                                    <button
                                        className="btn btn--primary w-[36px] h-[36px] flex justify-center items-center"
                                        onClick={() => panZoom?.zoomOut()}
                                    >
                                        <IconMinus />
                                        <span className="sr-only">Zoom Out</span>
                                    </button>
                                </div>

                                {!isImageLoaded && (
                                    <div className="absolute w-full h-full top-1/2">
                                        <div className="relative flex justify-center">
                                            <span className="font-serif text-2xl animate-pulse">Loading...</span>
                                        </div>
                                    </div>
                                )}

                                <div className={`w-full`}>
                                    <div className="flex items-center justify-center relative h-screen overflow-hidden">
                                        <div className="relative zoomNode">
                                            <img
                                                ref={imageRef}
                                                className="max-h-full transition-all"
                                                alt={''}
                                                src={image.url_full}
                                                onLoad={() => {
                                                    const panzoomElem = document.querySelector(
                                                        '.zoomNode'
                                                    ) as HTMLImageElement;
                                                    const panState = new PanZoomController(panzoomElem);
                                                    setPanZoom(panState);
                                                    setIsImageLoaded(true);
                                                }}
                                            />

                                            {isImageLoaded && image.hotspots
                                                ? image.hotspots.map((hotspot, index) => {
                                                      return (
                                                          <Hotspot
                                                              hotspot={hotspot}
                                                              key={`${hotspot}-${hotspot.x}-${hotspot.y}`}
                                                          />
                                                      );
                                                  })
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default ModalZoom;
