'use client';
import { IconArrowCaret, LogoMoar } from '@moar/svg';
import { TypeIntro } from '@moar/types';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import AnimateFadeIn from '../animate-fade-in/animate-fade-in';
import BannerPwaPrompt from '../banner-pwa-prompt/banner-pwa-prompt';
import { useIsOnline } from '../context/online';
import ErrorBoundaryApp from '../error-boundary-app/error-boundary-app';
import { Hxl } from '../headers/headers';
import useResponsive from '../hooks/use-responsive/use-responsive';
import useWindowSize from '../hooks/use-window-size/use-window-size';
import ImageCredit from '../image-credit/image-credit';
import ModalImagesCache from '../modal-images-cache/modal-images-cache';
import IntroBackground from './intro-background';
import { useIsPwa } from '../context/pwaContext';

export interface IntroProps {
    preview: boolean;
    intro: TypeIntro;
    linkToSite: string;
    title: string;
    paragraph: string;
}

export function Intro(props: IntroProps) {
    const windowSize = useWindowSize();
    const { preview, intro, linkToSite, title, paragraph } = props;
    const [isTruncated, setIsTruncated] = useState(false);
    const { isMobile } = useResponsive();
    const isOnline = useIsOnline();
    // const isPwa = useIsPwa();

    useEffect(() => {
        setIsTruncated(paragraph.length > 380);
    }, []);

    const {
        date,
        buttonLabel,
        backgroundImageDesktop,
        backgroundImageMobile,
        backgroundVideo,
        backgroundVideoCaptionFile,
        creditVideo,
        creditImage,
    } = intro;

    const backgroundImage = isMobile ? backgroundImageMobile : backgroundImageDesktop;
    const showVideo = backgroundVideo && isOnline;

    const introHeight = windowSize && windowSize.height && windowSize.height > 0 ? `${windowSize.height}px` : '100vh';

    return (
        <ErrorBoundaryApp>
            {/* {isPwa && (
                <div className="mode--standalone">
                    <ModalImagesCache />
                </div>
            )} */}
            <div
                className={`w-screen bg-blue ${!windowSize.height ? 'h-full' : ''}`}
                style={{
                    height: introHeight,
                }}
            >
                <AnimateFadeIn>
                    <IntroBackground
                        backgroundImage={backgroundImage}
                        backgroundVideo={backgroundVideo}
                        captionsUrl={backgroundVideoCaptionFile}
                    />

                    <div
                        className={`absolute w-screen bg-blue opacity-[.85] p-2.5 top-0 left-0 ${
                            !windowSize.height ? 'h-full' : ''
                        }`}
                        style={{ height: windowSize.height }}
                    >
                        <div className="h-full md:border md:border-white"></div>
                    </div>
                    {preview && (
                        <div className="absolute right-5 bottom-16 md:bottom-20">
                            <span className="ml-3 btn btn--primary">PREVIEW</span>
                        </div>
                    )}
                    {(creditVideo || creditImage) && (
                        <ImageCredit creditText={showVideo && creditVideo ? creditVideo : creditImage} mode="intro" />
                    )}
                    {!!linkToSite && isOnline && (
                        <div className="absolute z-30 left-5 top-5 md:left-8 md:top-8">
                            <Link href={linkToSite} className="flex flex-row text-xs font-bold link--white group">
                                <IconArrowCaret direction="left" color="white" />{' '}
                                <span className="ml-2 group-hover:text-yellow-dark">Back to Museum Website</span>
                            </Link>
                        </div>
                    )}
                    <div
                        className={`absolute top-0 left-0 flex flex-col items-center justify-center w-screen ${
                            !windowSize.height ? 'h-full' : ''
                        }`}
                        style={{ height: introHeight }}
                    >
                        <div className="flex flex-col items-center justify-center max-w-4xl px-4 text-center md:px-8">
                            <div className="mb-8">
                                <LogoMoar />
                            </div>
                            <span className="mb-2 font-sans font-normal text-white text-base-plus">{date}</span>
                            <Hxl textColor="text-white">{title}</Hxl>
                            <div className="text-center">
                                <div
                                    className={`${
                                        isTruncated ? 'line-clamp-4' : ''
                                    } max-w-3xl mx-auto mt-4 font-sans text-base font-normal text-center text-white md:line-clamp-none`}
                                    dangerouslySetInnerHTML={{ __html: paragraph }}
                                ></div>
                                {isTruncated && (
                                    <button
                                        tabIndex={0}
                                        aria-label={`${isTruncated ? 'Read More' : 'Read Less'}`}
                                        onClick={() => setIsTruncated(!isTruncated)}
                                        className="inline-block mt-2 link md:hidden"
                                    >
                                        Read More
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="mt-6">
                            <Link href="/sections#nav-open" className="btn btn--primary">
                                {buttonLabel}
                            </Link>
                        </div>
                    </div>
                </AnimateFadeIn>
            </div>
        </ErrorBoundaryApp>
    );
}
