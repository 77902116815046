'use client';
import { TypeSection } from '@moar/types';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useContextData } from './data';

export const ContextVerticalNavSectionOverlap = createContext<
    [TypeSection | null | undefined, (setCurrentSection: TypeSection | null | undefined) => void]
    // eslint-disable-next-line @typescript-eslint/no-empty-function
>([null, () => {}]);

function elementsOverlap(el1: HTMLElement | null, el2: HTMLElement | null) {
    if (!el1 || !el2) return;
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return !(
        domRect1.top > domRect2.bottom ||
        domRect1.right < domRect2.left ||
        domRect1.bottom < domRect2.top ||
        domRect1.left > domRect2.right
    );
}

// Used for desktop and mobile although vertical nav is hidden on mobile, still used for calculating current section
export function ProviderVerticalNavSectionOverlap({ children }: { children: ReactNode }) {
    const data = useContextData();
    const [currentSection, setCurrentSection] = useState<null | undefined | TypeSection>(null);
    // const asPath = usePathname();
    const { asPath } = useRouter();

    useEffect(() => {
        if (!data || !asPath?.startsWith('/sections')) return;

        const hash = asPath?.split('#')[1];

        if (!hash) {
            setCurrentSection(data.data.timelineSections.data[0]);
            return;
        }

        // set current section by section hash
        if (hash.startsWith('section-')) {
            const defaultSectionIndex = hash
                ? data.data.timelineSections.data.findIndex((section) => section.slug === hash.replace('section-', ''))
                : 0;
            console.log('setting current section by section hash', defaultSectionIndex);
            setCurrentSection(data.data.timelineSections.data[defaultSectionIndex]);
            return;
        }
        // has hash for object
        const sectionIndex = data.data.timelineSections.data.findIndex((section) =>
            section.objects?.find((object) => 'slug' in object && object.slug === hash)
        );
        if (sectionIndex !== -1) {
            setCurrentSection(data.data.timelineSections.data[sectionIndex]);
        }
    }, [data, asPath]);

    return (
        <ContextVerticalNavSectionOverlap.Provider value={[currentSection, setCurrentSection]}>
            {children}
        </ContextVerticalNavSectionOverlap.Provider>
    );
}

function useContextVerticalNavSectionOverlap() {
    return useContext(ContextVerticalNavSectionOverlap);
}

// change color of vertical nav based on which section it overlaps
export const useVerticalNavSectionOverlap = () => {
    const data = useContextData();
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [currentSection, setCurrentSection] = useContextVerticalNavSectionOverlap();
    const [offset, setOffset] = useState(0);
    const [currentSectionProgress, setCurrentSectionProgress] = useState(0);
    const [doesOverlapIntro, setDoesOverlapIntro] = useState(false);

    const getNewCurrentSectionIndex = () => {
        if (!data) return;
        const verticalNavEl = document.getElementById('vertical-nav');
        const sectionEls = data?.data.timelineSections.data.map((section) => {
            return document.getElementById(`section-${section.slug}`);
        });

        if (!sectionEls) return;

        const index = sectionEls.findIndex((section) => elementsOverlap(verticalNavEl, section));
        return index;
    };

    // update progress
    useEffect(() => {
        if (!currentSection) return;
        const currentSectionEl = document.getElementById(`section-${currentSection.slug}`);
        if (!currentSectionEl) return;
        const rect = currentSectionEl?.getBoundingClientRect();

        const progress = Math.floor(((offset - currentSectionEl.offsetTop) / rect.height) * 100);
        setCurrentSectionProgress(progress < 0 ? 0 : progress);
    }, [offset, data, currentSection]);

    // change section
    useEffect(() => {
        if (!data) return;

        const newCurrentSectionIndex = getNewCurrentSectionIndex();
        if (typeof newCurrentSectionIndex === 'undefined') return;

        if (newCurrentSectionIndex !== currentSectionIndex) {
            setCurrentSectionIndex(newCurrentSectionIndex);
            setCurrentSection(data.data.timelineSections.data[newCurrentSectionIndex]);
        }
    }, [offset, data]);

    // detect if nav is over section intro to keep it white
    useEffect(() => {
        if (!data || !currentSection) return;

        const introEl = document.getElementById(`section-intro-${currentSection.slug}`);
        const verticalNavEl = document.getElementById('vertical-nav');
        if (!introEl || !verticalNavEl) return;

        const doesOverlap = elementsOverlap(verticalNavEl, introEl);
        setDoesOverlapIntro(!!doesOverlap);
    }, [offset, data, currentSection]);

    useEffect(() => {
        setOffset(window.pageYOffset);
        const onScroll = () => {
            setOffset(window.pageYOffset);
        };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return { currentSection, currentSectionIndex, currentSectionProgress, doesOverlapIntro };
};
