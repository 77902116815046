import { IconArrow } from './lib/icons/icon-arrow';
import { IconArrowCaret } from './lib/icons/icon-arrow-caret';
import { IconArrowCircle } from './lib/icons/icon-arrow-circle';
import { IconDownload } from './lib/icons/icon-download';
import { IconICircle } from './lib/icons/icon-i-circle';
import { IconClose } from './lib/icons/icon-close';
import { IconCloseBox } from './lib/icons/icon-close-box';
import { IconCloseCircle } from './lib/icons/icon-close-circle';
import { IconCloseCircleBig } from './lib/icons/icon-close-circle-big';
import { IconFacebook } from './lib/icons/icon-facebook';
import { IconGear } from './lib/icons/icon-gear';
import { IconTwitter } from './lib/icons/icon-twitter';
import { IconInstagram } from './lib/icons/icon-instagram';
import { IconYoutube } from './lib/icons/icon-youtube';
import { IconMagnifyingGlass } from './lib/icons/icon-magnifying-glass';
import { IconMenu } from './lib/icons/icon-menu';
import { IconMinus } from './lib/icons/icon-minus';
import { IconPause } from './lib/icons/icon-pause';
import { IconPlay } from './lib/icons/icon-play';
import { IconPlus } from './lib/icons/icon-plus';
import { IconPrint } from './lib/icons/icon-print';
import { IconShare } from './lib/icons/icon-share';
import { IconShareLink } from './lib/icons/icon-share-link';
import { IconStar } from './lib/icons/icon-star';
import { IconVideo } from './lib/icons/icon-video';
import { IconZoom } from './lib/icons/icon-zoom';
import { LogoMoar } from './lib/logo-moar';
import { LogoMoarColor } from './lib/logo-moar-color';

export {
    IconArrow,
    IconArrowCaret,
    IconArrowCircle,
    IconDownload,
    IconICircle,
    IconClose,
    IconCloseBox,
    IconCloseCircle,
    IconCloseCircleBig,
    IconFacebook,
    IconGear,
    IconInstagram,
    IconMagnifyingGlass,
    IconMenu,
    IconMinus,
    IconPause,
    IconPlay,
    IconPlus,
    IconPrint,
    IconShare,
    IconShareLink,
    IconStar,
    IconTwitter,
    IconYoutube,
    IconVideo,
    IconZoom,
    LogoMoar,
    LogoMoarColor,
};
